import React from 'react';
import { Box, Paper, Typography, Divider, Avatar, Button } from '@mui/material';
import CustomButton from './CustomButton';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AssistantIcon from '@mui/icons-material/Assistant';

const DisplayBox = ({ header, results, footer, onButtonClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignSelf: 'flex-start',
        maxWidth: '80%',
        margin: '8px 0'
      }}
    >
      <Avatar
        sx={{
          bgcolor: 'secondary.main',
          marginRight: 2,
        }}
      >
        <AssistantIcon />
      </Avatar>
      <Paper
        elevation={2}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'grey.100',
          borderRadius: 2,
        }}
      >
        {/* Header */}
        {header && (
          <>
            <Box sx={{ p: 1.5, backgroundColor: 'primary.main', color: 'white', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
              <Typography variant="subtitle1">
                {header.split('\n').map((line, index) => (
                  <React.Fragment key={index}>{line}</React.Fragment>
                ))}
              </Typography>
            </Box>
            <Divider />
          </>
        )}
        {/* Results */}
        {results && results.length > 0 && (
          <Box sx={{ 
            p: 1.5, 
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            maxHeight: '300px',
            overflow: 'auto'
          }}>
            {results.map((result, index) => (
              typeof result === 'string' ? ( 
                <Typography key={index} variant="body1">
                  {result.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>{line}</React.Fragment>
                  ))}
                </Typography>
              ) : (
                <CustomButton
                  key={index}
                  buttonText={result.display_name.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>{line}</React.Fragment>
                  ))}
                  displayText={result.reply}
                  result={result}
                  idType={result.id_type}
                  idValue={result.id_value}
                  onClick={onButtonClick}
                />
              )
            ))}
          </Box>
        )}

        {/* Footer */}
        {footer && (
          <>
            <Divider />
            <Box sx={{ p: 1.5, backgroundColor: 'grey.200', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
              <Typography variant="subtitle1" color="text.secondary">
                {footer.split('\n').map((line, index) => (
                  <React.Fragment key={index}>{line}</React.Fragment>
                ))}
              </Typography>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DisplayBox;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Box, Typography, Divider, Hidden } from "@mui/material";
import { useSelector } from 'react-redux'
import { storeCTAClick } from '../../helpers'
import footerLogo from '../../images/footerLogo.gif'
import {
    Mail,
    MobileFriendlySharp
} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import SocialAvailability from "./SocialAvailability";
import logo from "../../images/coalshastra-logo-white.png";
// import fb from '../../../public/images/_fb.png'
// import MandiLogo from "../../images/"
import LanguageTranslate from "./UnderScoreLanguage";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";
import trackingEvents from "../../eventsTracking";

const useStyles = makeStyles(theme => ({
    footer: {
        color: "#ffffff9e",
        backgroundColor: "#19243b",
        paddingTop: theme.spacing(8),
        // paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(2)
        },
        // position: 'fixed',
        bottom: 0,
        width: '100%'
    },
    widget: {
        "& a": {
            color: "#ffffff9e"
        },
        "& li": {
            listStyle: "none",
            padding: theme.spacing(0),
            lineHeight: 2.5,
            "&:hover a": {
                color: "#fff"
            }
        },
        padding: theme.spacing(0)
    },
    widgetNoTitle: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(0)
        }
    },
    footerLogo: {
        maxWidth: 180
    },
    copyright: {
        fontSize: '.75rem',
        borderTop: '1px solid #344362',
        "& a": {
            color: "#ffffff9e",
            display: 'ínline-block'
        },
    },
    textAlignBreakpoint: {
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
        }
    },

}));


export default function Footer() {
    const classes = useStyles();
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn)
    const user_mode = useSelector(state => state.userReducer.user_mode)
    const [contracts, setContracts] = React.useState([])

    const fetchContracts = async () => {
      var params = {limit: 100}
      try {
        const res = await CommodityCommon.Contracts(params);
        // console.log('fetch contracts res', {res})
        setContracts(res.data.data.contracts);
      } catch (error) {
        console.log('error fetching contracts', {error})
      }
    }
    React.useEffect(() => {
      fetchContracts();
    }, [])

    return (
        <footer className={classes.footer}>
          <Hidden smDown>
            <Container>
                <Grid container spacing={5} mb={3} >
                    <Grid item xs={12} md={4}>
                        <Box mb={1}>
                            <Link to="/" aria-label="Go to homepage" onClick={()=>trackingEvents.footerHomePage()}>
                                <img src={footerLogo} alt="CoalShastra" className={classes.footerLogo} />
                               
                            </Link>
                        </Box>
                        <Box style={{marginBottom:'5px', height: '50px'}}>
                            Mandi has been established to bring equal opportunity to all.
                        </Box>
                       <Box> <SocialAvailability  /></Box>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography component="h2" variant="h5">
                            <LanguageTranslate s="Quick Links" />
                        </Typography>
                        <Box component="ul" className={classes.widget}>
                            <Box component="li">
                                <Link to="/" title="Home" onClick={e => { 
                                  // storeCTAClick(e, 'home-button'); 
                                  // return e;
                                  trackingEvents.footerHomePage()}}>
                                    <LanguageTranslate s="Home" />
                                </Link>
                            </Box>
                            {/* {(!isLoggedIn || (isLoggedIn && user_mode === 1)) &&
                            <Box component="li">
                                <Link to="/seller-map" title="Products" onClick={e => { storeCTAClick(e, 'product-requirements-button'); return e;}}>
                                    <LanguageTranslate s="Products" />
                                </Link>
                            </Box>
                            } */}
                            {/* {(!isLoggedIn || (isLoggedIn && user_mode === 2)) &&
                            <Box component="li">
                                <Link to="/buyer-map" title="Requirements" onClick={e => { storeCTAClick(e, 'product-requirements-button'); return e;}}>
                                    <LanguageTranslate s="Requirements" />
                                </Link>
                            </Box>
                            } */}
                            {/* {(!isLoggedIn || (isLoggedIn && user_mode === 1)) &&
                            <Box component="li">
                                <Link to="/requirements/buyer" title="Add listing" onClick={e => { storeCTAClick(e, 'add-product-requirements-button-bottom-navigation'); return e;}}>
                                    <LanguageTranslate s="Add Requirement" />
                                </Link>
                            </Box>
                            } */}

                            <Box component="li">
                            {
                            // (!isLoggedIn || (isLoggedIn && user_mode === 1)) ?
                                // <Link to="/listings" title="Browse Seller listings" onClick={e => { storeCTAClick(e, 'product-requirements-button'); return e;}}>
                                //     <LanguageTranslate s="Products" />
                                // </Link>
                                // : (!isLoggedIn || (isLoggedIn && user_mode === 2)) ?
                                // <Link to="/listings" title="Browse Buyer listings" onClick={e => { storeCTAClick(e, 'product-requirements-button'); return e;}}>
                                //     <LanguageTranslate s="Products" />
                                // </Link>
                                // : ""
                                <Link to="/markets" title="Browse Buyer listings" onClick={e => { 
                                  // storeCTAClick(e, 'product-requirements-button'); return e;
                                  trackingEvents.footerProducts()
                                  }}>
                                     <LanguageTranslate s="Products" />
                                 </Link>
                            }
                            </Box>
                            <Box component="li">
                            {(!isLoggedIn || (isLoggedIn && user_mode === 2)) ?
                                <Link to="/requirements/seller" title="Add listing" onClick={e => { storeCTAClick(e, 'add-product-requirements-button-bottom-navigation'); return e;}}>
                                    <LanguageTranslate s="Add Listing" />
                                </Link>
                            : (!isLoggedIn || (isLoggedIn && user_mode === 1)) ?
                                <Link to="/requirements/buyer" title="Add listing" onClick={e => { storeCTAClick(e, 'add-product-requirements-button-bottom-navigation'); return e;}}>
                                    <LanguageTranslate s="Add Listing" />
                                </Link>
                            : ""
                            }
                            </Box>
                            <Box component="li">
                                <Link to="/events" title="Upcoming events">
                                    <LanguageTranslate s="Upcoming Events" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography component="h2" variant="h5">
                            <LanguageTranslate s="Company" />
                        </Typography>
                        <Box
                            component="ul"
                            className={`${classes.widget}`}
                        >
                            <Box component="li">
                                <Link to="/about-us" title="" onClick={()=>trackingEvents.aboutUs()}>
                                    <LanguageTranslate s="About Us" />
                                </Link>
                            </Box>
                            {/* <Box component="li">
                                <Link to="/blog" title="">
                                    <LanguageTranslate s="Blog" />
                                </Link>
                            </Box>
                            <Box component="li">
                                <Link to="/media-kit" title="">
                                    <LanguageTranslate s="Mediakit" />
                                </Link>
                            </Box>
                            <Box component="li">
                                <Link to="/news" title="">
                                    <LanguageTranslate s="News" />
                                </Link>
                            </Box>
                            <Box component="li">
                                <Link to="/events" title="">
                                    <LanguageTranslate s="In Media & Events" />
                                </Link>
                            </Box> */}
                            <Box component="li">
                                <Link to="/contact" title="" onClick={()=>trackingEvents.contactUs()} >
                                    <LanguageTranslate s="Contact Us" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
              
                    <Grid item xs={12} md={3}>
                        <Typography component="h2" variant="h5">
                            <LanguageTranslate s="Contact Us" />
                        </Typography>
                        <Box component="ul" className={classes.widget}>
                            <Box component="li" onClick={()=>trackingEvents.contactNumber()}>
                                <MobileFriendlySharp
                                    fontSize="small"
                                    className="vAlign-text-top"
                                />
                                &nbsp;
                                <a href="tel:+919315950241 ">
                                    
 +91 93159 50241 
                                </a>
                            </Box>
                            <Box component="li" onClick={()=>trackingEvents.emailId()}>
                                <Mail
                                    fontSize="small"
                                    className="vAlign-text-top"
                                />
                                &nbsp;<a href="mailto:support@Mandi.trade">support@mandi.trade</a>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
     
                <Grid item sm={12} style={{ padding: 0, margin: 0 }}>
                  {/* <Grid item sm={12} style={margin: 0, padding: 0}> */}
                    <Typography component="h2" variant="h5" style={{textAlign:"center",marginTop:5}}>
                      Products
                    </Typography>
                    <Box
                      component="ul"
                      className={`${classes.widget}`}
                      style={{
                        display: 'grid',
                        // grid-template-columns: "repeat(auto-fit, minmax(100px, 1fr))",
                        gridTemplateColumns:"repeat(auto-fit, minmax(150px, 1fr))",
                        textAlign: "left",
                        gap: 0,
                        padding: 2,
                        margin: 0,
                        listStyleType: "none",
                      }}
                    >
                    {contracts && contracts.map((contract, index) => (
                        <Box component="li" key={contract._id} style={{ margin: 0, padding: 0 }} onClick={()=>trackingEvents.footerContracts({contract: contract.name})}>
                          <Link to={`markets/${contract.commodity.commodityType.slug}/${contract.commodity.slug}`} title="">
                            {contract.name}
                          </Link>
                        </Box>
                    ))} 
                    </Box>
                    {/* <Box
                      component="ul"
                      className={`${classes.widget}`}
                      style={{
                        display: 'grid',
                        // grid-template-columns: "repeat(auto-fit, minmax(100px, 1fr))",
                        gridTemplateColumns:"repeat(auto-fit, minmax(350px, 1fr))",
                        textAlign: "center",
                      }}
                    >
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Mango Plant (Aam) - KESAR
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Guava (Amrood) - ALLAHABADI SAFEDA AMRUD
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Mango Plant (Aam) - ALL TIME
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Mango Plant (Aam) - DASHERI
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Chikoo (Sapota) - KALI PATTI
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Chikoo (Sapota) - CRICKET BALL
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Guava (Amrood) - TAIWAN PINK
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Guava (Amrood) - RED DIAMOND
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Citrus LEMON - KOLKATA PATTI - KAGAZI
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Citrus - LEMON - BALAJI
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Mahogany - AFRICAN MAHOGANY
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Palm Tree - Plant - DATE PALM
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Palm Tree - Plant - WASHINGTONIA PALM
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Palm Tree - Plant - TERMINALIA MANTALY GREEN
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Mango Plant (Aam) - AMRAPALI
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Palm Tree - Plant - BOTTLE PALM
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link to="/sign-up/MA==" title="">
                          Palm Tree - Plant - FOXTAIL PALM
                        </Link>
                      </Box>
                    </Box> */}
                </Grid>  
            </Container>

            <Box pb={3} pt={3} className={classes.copyright}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            &copy; {new Date().getFullYear()} Mandi.trade.com
                        </Grid>
                        <Grid item xs={12} sm>
                            <Box className={classes.textAlignBreakpoint}>
                                <Link to="/refund-policy">
                                    <LanguageTranslate s="Refund Policy" />
                                </Link>
                                &nbsp;|&nbsp;
                                <Link to="/privacy-policy">
                                    <LanguageTranslate s="Privacy Policy" />
                                </Link>
                                &nbsp;|&nbsp;
                                {/* <Link to="/awarded-deal-quote-cancellation-policy">
                                    <LanguageTranslate s="Awarded Deal/Quote Cancellation" />
                                </Link>
                                &nbsp;|&nbsp; */}
                                <Link to="/term-services">
                                    <LanguageTranslate s="Terms of Service" />
                                </Link>
                                {/* &nbsp;|&nbsp;
                                <Link to="/sitemap.xml">
                                    <LanguageTranslate s="Sitemap" />
                                </Link> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            </Hidden>
        </footer>
    );
}

import React from 'react';
import { Box, Typography, Avatar, Button } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AssistantIcon from '@mui/icons-material/Assistant';
import PersonIcon from '@mui/icons-material/Person';
import DisplayBox from './DisplayBox';


const MessageList = ({ messages, onButtonClick }) => {
  const remainingMessages = [...messages];
  remainingMessages.splice(0, 1);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {remainingMessages.map((message) => {
        if (message.type === 'display_box') {
          return (
            <DisplayBox
              key={message.id}
              header={message.displayData.header}
              results={message.displayData.result}
              footer={message.displayData.footer}
              onButtonClick={onButtonClick}
            />
          );
        }

        return (
          <Box
            key={message.id}
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-start',
              flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
            }}
          >
            <Avatar
              sx={{
                bgcolor: message.sender === 'user' ? 'primary.main' : 'secondary.main',
              }}
            >
              {message.sender === 'user' ? <PersonIcon /> : <AssistantIcon />}
            </Avatar>
            <Box
              sx={{
                backgroundColor: message.sender === 'user' ? 'primary.light' : 'grey.100',
                borderRadius: 2,
                p: 2,
                maxWidth: '70%',
              }}
            >
              {/* <Typography 
                variant="body1" 
                component="div"
                dangerouslySetInnerHTML={{ __html: message.text }}
              /> */}
              <Typography variant="body1" color="text.secondary" display="block">
                {message.text.split('\n').map((line, i) => (
                  // <Button onClick={()=>console.log(line)}>line</Button>
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                {new Date(message.timestamp).toLocaleTimeString()}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MessageList;

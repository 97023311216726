import resource from 'resource-axios';
// import axios from '../base';
import axios from '../baseCommodity';
import CryptoJS from 'crypto-js'
import { config } from '../../config';

export const User = resource(
    config.api_url,
    {
        // bookmarks: {
        //     vesselToggle: (params) => {
        //         return axios.post(
        //             `${config.api_url}/bookmarks/vessels/toggle`,
        //             params
        //         );
        //     },
        //     vesselAttributeToggle: (params) => {
        //         return axios.post(
        //             `${config.api_url}/bookmarks/vessel-attribute/toggle`,
        //             params
        //         );
        //     },
        // },

        // fetchTempSocialUser: (params) => {
        //     return axios.post(
        //         `${config.api_url}/login/fetch-temp-user`,
        //         params
        //     );
        // },

        // saveAndSendOtpWithSocialNetworksLogin: (params) => {
        //     return axios.post(
        //         `${config.api_url}/save-and-send-otp-with-social-login`,
        //         params
        //     );
        // },

        login: (params) => {
            return axios.post(config.login_url, params);
        },

        // verifyEmail: (token) => {
        //     return axios.get(`${config.api_url}/verifyuser/${token}`);
        // },
        // resetForgotPassword: (token, params) => {
        //     return axios.post(
        //         `${config.api_url}/reset-password/${token}`,
        //         params
        //     );
        // },

        // User signup request
        // signUp: {
        //     tempRegister: (params) => {
        //         return axios.post(`${config.api_url}/tempregister`, params);
        //     },
        //     submit: (params) => {
        //         return axios.post(
        //             `${config.api_url}/user-registration-with-verified-otp`,
        //             params
        //         );
        //     },
        //     submitV2: (params) => {
        //         return axios.post(
        //             `${config.api_url}/user-registration-with-verified-otp-v2`,
        //             params
        //         );
        //     },
        //     otpCheck: (params) => {
        //         return axios.post(`${config.api_url}/otpCheck`, params);
        //     },

        //     resendOtp: (params) => {
        //         return axios.post(`${config.api_url}/resendotp`, params);
        //     },
        // },

        logout: () => {
            return axios.post(`${config.api_url}/logout`, { mode: 1 });
        },

        // addCompany: (params) => {
        //     return axios.post(`${config.api_url}/addCompany`, params);
        // },

        // addInternationalCompany: (params) => {
        //     return axios.post(`${config.api_url}/store-international-company`, params);
        // },

        // // Get marketplace by country
        // getMarketplaceByCountry: (params) => {
        //     var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        //     return axios.get(`${config.api_url}/marketplace/get-marketplace-by-country?${queryString}`, params)
        // },

        // addCompanyKyc: (params) => {
        //     return axios.post(`${config.api_url}/kycCheck`, params);
        // },

        // getCompany: (params) => {
        //     return axios.post(`${config.api_url}/getCompany`, params);
        // },


       
     
        // Get trending contracts
//         trendingContract: (params) => {
//             if (!params || params === undefined) {
//                 params = {};
//             }
//             var queryString = Object.keys(params)
//                 .map((key) => key + "=" + params[key])
//                 .join("&");
//             return axios.get(
//                 `${config.api_url}/get-trending-contracts?${queryString}`
//             );
//         },

//         // Get Most Active contracts
//         mostActiveContract: (params) => {
//             if (!params || params === undefined) {
//                 params = {};
//             }
//             var queryString = Object.keys(params)
//                 .map((key) => key + "=" + params[key])
//                 .join("&");
//             return axios.get(
//                 `${config.api_url}/get-most-active-contracts?${queryString}`
//             );
//         },

//         seoListing:()=>{
// return axios.get(`${config.api_url}/user/listings`)
//         },

//         // Help center(HC)
//         getQueryHCTitles: (params) => {
//             return axios.get(
//                 `${config.api_url}/help-center-query-titles`,
//                 params
//             );
//         },
//         HCRaiseQuery: (params) => {
//             // var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
//             return axios.post(`${config.api_url}/raise-query-by-user`, params);
//         },

        getUserFromToken: () => {
            return axios.get(config.common_api_commodity_url + "/user/profile");
        },
        saveBookmarkedData:(params)=>{
return axios.patch(`${config.common_api_commodity_url}/user/profile/fav-contract/${params}`)
        }, removBookmarkedData:(params)=>{
            return axios.patch(`${config.common_api_commodity_url}/user/profile/fav-contract/${params}/remove`)
                    },
        checkAndLoad: async () => {
            return axios.get(config.common_api_commodity_url + "/user/profile");
        },

        fetchNotifications: (params) => {
            return axios.get(`${config.common_api_commodity_url}/user/notifications`, params);
        },

        checkGSTNumber: (params) => {
            return axios.post(`${config.common_api_commodity_url}/user/companies/gst-exists`, params);
        },

        // sendVerificationCode: (params) => {
        //     return axios.post(
        //         `${config.api_url}/send-instant-verification-otp`,
        //         params
        //     );
        // },

        // // Profile update
        // getReferenceCode: (params) => {
        //     return axios.post(
        //         `${config.api_url}/send-otp-for-verification`,
        //         params
        //     );
        // },

        // verifyOtp: (params) => {
        //     return axios.post(
        //         `${config.api_url}/verify-instant-verification-otp`,
        //         params
        //     );
        // },

        // updateUserProfileContact: (params) => {
        //     return axios.post(
        //         `${config.api_url}/update-user-phone-number`,
        //         params
        //     );
        // },

        chats: {
            fetch: (params) => {
                return axios.get(`${config.common_api_commodity_url}/user/communications/inbox`);
            },
        //     sendMessageOld: (params) => {
        //         return axios.post(
        //             `${config.api_url}/sendmessage?platform_key=1`,
        //             params
        //         );
        //     },
        //     sendMessage: (params) => {
        //         return axios.post(
        //             `${config.api_url}/chat/v3/send-message?platform_key=1`,
        //             params
        //         );
        //     },
        //     communicationYes: (params) => {
        //         return axios.post(
        //             `${config.api_url}/chat/v3/communication/yes`,
        //             params
        //         );
        //     },
        //     communicationNo: (params) => {
        //         return axios.post(
        //             `${config.api_url}/chat/v3/communication/no`,
        //             params
        //         );
        //     },
        //     channelize: {
        //         login: (params) => {
        //             return axios.post(
        //                 `${config.api_url}/channelize/login`,
        //                 params
        //             );
        //         },
        //         getChannelizeUserId: (params) => {
        //             return axios.post(
        //                 `${config.api_url}/channelize/userToChannelizeUser`,
        //                 params
        //             );
        //         },
        //     },
        },

        // fetchRatingQue: (params) => {
        //     return axios.post(`${config.api_url}/fetchratingquestion`, params);
        // },

        // postRating: (params) => {
        //     return axios.post(`${config.api_url}/postrating`, params);
        // },

        // postReviewRating: (params) => {
        //     return axios.post(`${config.api_url}/reRating`, params);
        // },

        // resetPassword: (params) => {
        //     return axios.post(`${config.api_url}/forgotpassword`, params);
        // },

        // getRatings: (params) => {
        //     return axios.post(`${config.api_url}/globalprofile`, params);
        // },

        // subUsers: {
        //     create: (params) => {
        //         return axios.post(`${config.api_url}/createsubusers`, params);
        //     },
        //     getSubusersList: (params) => {
        //         return axios.post(`${config.api_url}/getsubuserlist`, params);
        //     },
        //     getSubusersRoles: (params) => {
        //         return axios.post(`${config.api_url}/getsubusersroles`, params);
        //     },
        //     otpVerification: (params) => {
        //         return axios.post(`${config.api_url}/verifysubuserotp`, params);
        //     },
        //     resendVerificationOtp: (params) => {
        //         return axios.post(`${config.api_url}/resendsubuserotp`, params);
        //     },
        // },

        // storeEyardAction: (params) => {
        //     return axios.post(
        //         `${config.api_url}/addaboutandlogotocompany`,
        //         params
        //     );
        // },

        // storeEkycAction: (params) => {
        //     return axios.post(`${config.api_url}/updatekkycfileupdate`, params);
        // },


        // updateCompanyData: (params) => {
        //     return axios.post(`${config.api_url}/profile/update-company-data`, params);
        // },

        // brandStore: (slug) => {
        //     return axios.get(`${config.api_url}/brand-stores/show/${slug}`)
        // },

        // storeUserUpdateProfileAction: (params) => {
        //     return axios.post(`${config.api_url}/UpdateProfile`, params);
        // },
        
     

        // allCompanyTransaction: (params) => {
        //     return axios.post(
        //         `${config.api_url}/allcompanytransaction`,
        //         params
        //     );
        // },

        // dealerSupportData: (params) => {
        //     return axios.post(
        //         `${config.api_url}/getassigneddealerinfo`,
        //         params
        //     );
        // },

        // checkGSTNumber: (params) => {
            // return axios.post(`${config.api_url}/checkGSTNumber`, params);
        // },

        // companyBadgesData: (companyId, params) => {
        //     var queryString = "";
        //     if (params) {
        //         queryString = Object.keys(params)
        //             .map((key) => key + "=" + params[key])
        //             .join("&");
        //     }
        //     return axios.get(
        //         `${config.api_url}/share-badges-profile/${companyId}?${queryString}`
        //     );
        // },

        // profileBadges: (params) => {
        //     return axios.post(`${config.api_url}/profile-badges`, params);
        // },

        // unreadNotificationCount: () => {
        //     return axios.post(`${config.api_url}/unread-notification-count`);
        // },

        // // Get market-list for selecting preferences
        // getMarketList: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.post(
        //         `${config.api_url}/marketplace/marketplace-list?${queryString}`
        //     );
        // },

         // Get market-list for selecting preferences for brand store
        //  getBrandStoreMarketList: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.post(
        //         `${config.api_url}/marketplace/brand-stores/marketplace-list?${queryString}`
        //     );
        // },

        // saveMarketPreference: (params) => {
        //     return axios.post(
        //         `${config.api_url}/marketplace/save-marketplace-preference`,
        //         params
        //     );
        // },

        // marketplaceTabs: (params) => {
        //     var queryString = "";
        //     if (params) {
        //         queryString = Object.keys(params)
        //             .map((key) => key + "=" + params[key])
        //             .join("&");
        //     }
        //     return axios.get(
        //         `${config.api_url}/marketplace/marketplace-tabs?${queryString}`
        //     );
        // },

        // marketplaceTabsV2: (params) => {
        //     var queryString = "";
        //     if (params) {
        //         queryString = Object.keys(params)
        //             .map((key) => key + "=" + params[key])
        //             .join("&");
        //     }
        //     return axios.get(
        //         `${config.api_url}/marketplace/marketplace-tabs-v2?${queryString}`
        //     );
        // },

        // marketplaceTabsBrandStorePost: (params) => {
        //     return axios.post(
        //         `${config.api_url}/marketplace/marketplace-tabs-brand-store`,
        //         params,
        //         {
        //             headers: {
        //                 "Content-Type": "multipart/form-data",
        //             },
        //         }
        //     );
        // },

        // marketplaceTabsV2Post: (params) => {
        //     return axios.post(
        //         `${config.api_url}/marketplace/marketplace-tabs-v2-post`,
        //         params,
        //         {
        //             headers: {
        //                 "Content-Type": "multipart/form-data",
        //             },
        //         }
        //     );
        // },

        // guestMarketplaceList: (params) => {
        //     var queryString = "";
        //     if (params) {
        //         queryString = Object.keys(params)
        //             .map((key) => key + "=" + params[key])
        //             .join("&");
        //     }
        //     return axios.get(
        //         `${config.api_url}/guest/marketplace-list-for-guest?${queryString}`
        //     );
        // },

        // guestMarketplaceTabs: (params) => {
        //     return axios.post(
        //         `${config.api_url}/guest/markettabs-for-guest`,
        //         params
        //     );
        // },

        // guestBenchmarkList: (params) => {
        //     var queryString = "";
        //     if (params) {
        //         queryString = Object.keys(params)
        //             .map((key) => key + "=" + params[key])
        //             .join("&");
        //     }
        //     return axios.get(
        //         `${config.api_url}/guest/benchmark-list-for-guest?${queryString}`
        //     );
        // },

        // adSpaceData: (params) => {
        //     var queryString = "";
        //     if (params) {
        //         queryString = Object.keys(params)
        //             .map((key) => key + "=" + params[key])
        //             .join("&");
        //     }
        //     return axios.get(
        //         `${config.api_url}/ad-spaces?${queryString}`
        //     );
        // },


        // guestBenchmarkDetails: (params) => {
        //     return axios.post(
        //         `${config.api_url}/guest/benchmark-details-for-guest`,
        //         params
        //     );
        // },

        // otherCoalProducts: (params) => {
        //     return axios.post(
        //         `${config.api_url}/marketplace/other-coal-products`,
        //         params
        //     );
        // },

        // captureUserResponse: (params) => {
        //     return axios.post(
        //         `${config.api_url}/marketplace/overshoot-marketplace-user`,
        //         params
        //     );
        // },

        // // Check Market
        // checkMarket: (params) => {
        //     return axios.post(`${config.api_url}/check-marketplace`, params);
        // },

        // getUnreadMsgCount: (params) => {
        //     return axios.post(`${config.api_url}/nav-bar-chat-count`, params);
        // },

        // // Get all pending ratings
        // getPendingRatings: (params) => {
        //     return axios.get(
        //         `${config.api_url}/get-all-pending-ratings`,
        //         params
        //     );
        // },

        // // Get user's wallet data
        // getWalletSummary: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/credit-consumption-summary?${queryString}`,
        //         params
        //     );
        // },

        // // Get negative list
        // getNegativeList: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/negative-list?${queryString}`,
        //         params
        //     );
        // },
        // getBrandStoreList: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/brand-stores?${queryString}`,
        //         params
        //     );
        // },

        // // Search User by contacts
        // searchUserByContact: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/search-user-by-contact-number?${queryString}`,
        //         params
        //     );
        // },

        // // Add user's to negative list
        // addToNegativeList: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.post(
        //         `${config.api_url}/negative-list/add-negative-list-manual?${queryString}`,
        //         params
        //     );
        // },

        // // Unblock negative listed user
        // unblockNegativeList: (params) => {
        //     return axios.post(
        //         `${config.api_url}/negative-list/unblock`,
        //         params
        //     );
        // },

        // // Request to review ratings through blocked companies
        // requestToReviewRating: (params) => {
        //     return axios.post(
        //         `${config.api_url}/request-to-review-rating`,
        //         params
        //     );
        // },

        // // Get rating to show blocked companies
        // getRating: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/get-rating?${queryString}`,
        //         params
        //     );
        // },

        // // Get user's reference contacts
        // getReferenceContacts: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/reference-contacts?${queryString}`,
        //         params
        //     );
        // },

        // saveRouteCount: (params) => {
        //     var queryString = Object.keys(params)
        //         .map((key) => key + "=" + params[key])
        //         .join("&");
        //     return axios.get(
        //         `${config.api_url}/save-route-count?${queryString}`,
        //         params
        //     );
        // },

        // // Save user's reference contacts
        // postReferenceContact: (params) => {
        //     return axios.post(
        //         `${config.api_url}/reference-contacts/create-multiple`,
        //         params
        //     );
        // },

        // // Update user's reference contact company
        // updateReferenceContactCompany: (params) => {
        //     return axios.post(
        //         `${config.api_url}/reference-contacts/update`,
        //         params
        //     );
        // },

        // Save user's reference contacts  reference_contact_id
        // removeReferenceContact: (params) => {
        //     return axios.post(
        //         `${config.api_url}/reference-contacts/delete`,
        //         params
        //     );
        // },

        // // Get user's reference contacts
        // atomPay: (params) => {
        //     //var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        //     return axios.post(`${config.api_url}/atom-pay/get-token?`, params);
        // },
        // loginViaToken: (params) => {
        //     return axios.post(
        //         `${config.api_url}/login-via-token`,
        //         params
        //     );
        // },
    },
    axios
);

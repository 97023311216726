import * as React from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import trackingEvents from '../../eventsTracking';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import MailIcon from '@mui/icons-material/Mail';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

const useStyles = makeStyles(theme => ({
    activeNavItem: {
        // color: theme.palette.primary.main,
        '& .MuiBottomNavigationAction-label': {
            fontSize: 13.5
        }
    },
    products:{
        
   
    },
    navItem: {
        // background: '#d4def1',
        // color: '#222',
        '& .MuiBottomNavigationAction-label': {
            fontSize: 13.5
        }
    }
}));


export default function BottomTabsNav(props) {

    const classes = useStyles();
    const location = useLocation();
    const ref = React.useRef(null);
    const [value, setValue] = React.useState(null);
    const [searchParams] = useSearchParams();
    const isBuyAction = searchParams.get('action') === 'buy';
    const isSellAction = searchParams.get('action') === 'sell';

    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
    }, [value]);

    return (
        <Box ref={ref}>
            {/* <CssBaseline /> */}
            <Paper sx={{ position: 'fixed', zIndex: 1000, bottom: 0, left: 0, right: 0 }} >
                <BottomNavigation
                    showLabels
                    sx={{ background: '#19243B' }}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr"}}
                    >
                    <BottomNavigationAction
                        label="Home"
                        component={Link}
                        to="/"
                        className={location.pathname == '/' ? classes.activeNavItem : classes.navItem}
                        // icon={location.pathname == '/' ? <img src="/images/home-active.svg" alt="home" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/home-default.svg" alt="home" width={22} height={22} style={{ width: 22, marginBottom: 4 }} />}
                        icon={location.pathname == '/' ? <HomeIcon sx={{ color: "#fff" }} /> : <HomeOutlinedIcon sx={{ color: 'primary.light' }} />}
                        style={
                            // location.pathname === '/' ?
                            // {color:"#FFF", marginLeft:"5px", backgroundColor: '#11151c'} : 
                            // {color:"#FFF", marginLeft:"2px"}
                            {color: location.pathname == '/' ? "#FFF" : "#6a7b83"}
                        }
                        onClick={()=>trackingEvents.homeButton()}
                    />
                        {/* <BottomNavigationAction
                        label="Buy"
                        component={Link}
                        to="/add-your-products?action=buy"
                        // className={isBuyAction ? classes.navItem : classes.navItem}
                        className={classes.navItem}
                        // icon={isBuyAction ? <img src="/images/buy-active.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/buy-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4, }} />}
                        icon={isBuyAction ? <LocalShippingOutlinedIcon sx={{ color: "#fff" }} /> : <LocalShippingOutlinedIcon sx={{ color: "primary.light" }} />}
                        // style={{ border: "2px #FFA500 solid", backgroundColor: "#FFA500", borderRadius: "20%",  color: "#FFF",marginLeft:5,marginRight:-5 }}
                        // style={{color:"#FFF",paddingLeft:"-15px",marginLeft:"-15px"}}
                        style={{color:"#FFF", marginLeft:"-15px"}}
                        onClick={()=>trackingEvents.buyButton()}
                    />  */}
                     <BottomNavigationAction
                        label="Sell"
                        component={Link}
                        to="/add-your-products?action=sell"
                        // className={location.pathname.startsWith('/markets') ? classes.navItem : classes.navItem}
                        // className={isSellAction ? classes.navItem : classes.navItem}
                        className={classes.navItem}
                        // icon={location.pathname.startsWith('/markets') ? <img src="/images/sell-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/sell-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4, }} />}
                        // icon={isSellAction ? <img src="/images/sell-active.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/sell-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4, }} />}
                        icon={isSellAction ? <LocalShippingIcon sx={{ color: "#fff" }} /> : <LocalShippingOutlinedIcon sx={{ color: "primary.light" }} />}
                        // style={{ border: "2px #FFA500 solid", backgroundColor: "#FFA500", borderRadius: "20%",  color: "#FFF",marginLeft:5,marginRight:-5 }}
                        // style={{color:"#FFF",paddingLeft:"-15px",marginLeft:"-15px"}}
                        // style={{color:"#FFF", marginRight:"5px"}}
                        style={{color: isSellAction ? "#FFF" : "#6a7b83"}}
                        onClick={()=>trackingEvents.sellButton()}
                    /> 
                    <BottomNavigationAction
                    // label="Products"
                    component={Link}
                    to="/markets"
                    icon={
                        <div style={{ position: "relative", display: 'flex', justifyContent: "center" }}>
                            <div
                                style={{
                                    width: 40,
                                    height: 40, 
                                    backgroundColor: "#FFA500", 
                                    borderRadius: "50%", 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "absolute",
                                    top: -20, 
                                    // left: 0,
                                    left: "50%",
                                    // right: 0,
                                    // margin: "0 auto", 
                                    // padding:"19px"
                                    transform: "translateX(-50%)",
                                }}
                                onClick={()=>trackingEvents.productsPage()}
                            >
                                {/* <img
                                    src="/images/markets-active.svg"
                                    alt="products"
                                    width={25} height={25} 
                                    style={{
                                        width: 25, 
                                        // filter: "invert(100%)", 
                                    }}
                                /> */}
                                {location.pathname.includes('markets') ? <StoreIcon sx={{ color: "#fff" }} /> : <StoreOutlinedIcon sx={{ color: 'primary.light' }} />}
                            </div>
                            <div style={{ textAlign: "center", marginTop: "25px", color: location.pathname.includes('markets') ? "#FFF" : "#6a7b83" }}>
                                Products
                            </div>
                        </div>
                    }
                    // style={{ color: "#FFF", marginLeft: "12px", marginRight: 3, position: "relative", marginTop: "10px" }}
                    style={{ color: "#fff", position: "relative" }}
                />
                   
                    {/* <BottomNavigationAction
                        label="Inbox"
                        component={Link}
                        to="/dashboard/inbox"
                        className={location.pathname == '/dashboard/inbox' ? classes.activeNavItem : classes.navItem}
                        icon={location.pathname == '/dashboard/inbox' ? <img src="/images/inbox-active.svg" alt="inbox" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/inbox-default.svg" alt="inbox" width={22} height={22} style={{ width: 22, marginBottom: 4, }} />}
                        style={{color:"#FFF"}}
                        onClick={()=>trackingEvents.myInbox()}
                    /> */}

                     <BottomNavigationAction
                        label="Inbox"
                        component={Link}
                        to="/dashboard/inbox"
                        // className={location.pathname.startsWith('/markets') ? classes.navItem : classes.navItem}
                        // className={isSellAction ? classes.navItem : classes.navItem}
                        className={classes.navItem}
                        // icon={location.pathname.startsWith('/markets') ? <img src="/images/sell-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/sell-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4, }} />}
                        // icon={isSellAction ? <img src="/images/sell-active.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/sell-default.png" alt="markets" width={22} height={22} style={{ width: 22, marginBottom: 4, }} />}
                        icon={location.pathname === '/dashboard/inbox' ? <MailIcon sx={{ color: "#fff" }} /> : <MailOutlinedIcon sx={{ color: "primary.light" }} />}
                        // style={{ border: "2px #FFA500 solid", backgroundColor: "#FFA500", borderRadius: "20%",  color: "#FFF",marginLeft:5,marginRight:-5 }}
                        // style={{color:"#FFF",paddingLeft:"-15px",marginLeft:"-15px"}}
                        style={{color: location.pathname === '/dashboard/inbox' ? "#FFF" : "#6a7b83"}}
                        onClick={()=>trackingEvents.myInbox()}
                    /> 
                     <BottomNavigationAction
                        label="My Listings"
                        component={Link}
                        sx={{ 
                            // whiteSpace: 'nowrap', 
                            // marginRight: "16px" ,
                            // // color: location.pathname == '/dashboard/my-listings' ? "#FFF" : "primary.light",
                            // // marginTop: 0
                        }}
                        to="/dashboard/my-listings"
                        className={location.pathname == '/dashboard/my-listings' ? classes.activeNavItem : classes.navItem}
                        // icon={location.pathname == '/dashboard/my-listings' ? <img src="/images/listings-active.svg" alt="home" width={22} height={22} style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/listings-default.svg" alt="listings" width={22} height={22} style={{ width: 22, marginBottom: 4 }} />}
                        icon={location.pathname == '/dashboard/my-listings' ? <InsertChartIcon sx={{ color: '#fff' }} /> : <InsertChartOutlinedIcon sx={{ color: 'primary.light' }} />}
                        style={{ 
                            whiteSpace: 'nowrap', 
                            marginRight: "16px" ,
                            color: location.pathname == '/dashboard/my-listings' ? "#FFF" : "#6a7b83",
                            marginTop: 1
                            // color: location.pathname == '/dashboard/my-listings' ? "red" : "green",
                            // marginTop: 1 
                        }}
                        onClick={()=>trackingEvents.myListings()}
                    />
                     
                </BottomNavigation>
            </Paper>
        </Box>
    );
}
import React from "react";
// import OneSignal from 'react-onesignal';
// import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, Routes, Route, useLocation, useParams } from "react-router-dom";
import { withSnackbar } from "notistack";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import { socket, connectWS } from '../../socket';
import posed, { PoseGroup } from "react-pose";
import Emitter from '../../services/emitter';
import NavbarV3 from '../partials/NavbarV3';
import NavbarBrandStore from '../partials/NavbarBrandStore'
import Homepage from "../homepage-v3/Index";
// import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from "@mui/material/LinearProgress";
// import Box from "@mui/material/Box";
import loadable from "@loadable/component";
// import PromptAddReference from '../app-download-banners/PromptAddReference'
import { config } from "../../config";
import SelectCommodities from "../select-commodities";

// import SocialLogin from '../partials/SocialLogin'
// import GlobalRatingSeller from '../profile/global-profile/GlobalRatingSeller';

// import ScheduleTruck from "../operations/ScheduleTrucks/ScheduleTruckTabs";
// import AddVehicle from "../operations/VehicleInfo/VehicleTabs"
// import PaymentConfirm from "../operations/PaymentConfirm/PaymentConfirmTabs"

// import ManagementConsole from '../console';

import OnLoadTransition from "./OnLoadTransition";
import AuthRoute from '../partials/AuthRoute';
// import BrandStoreLandingPage from "../pages/BrandStoreLandingPage";
import AddNewUser from "../signupComm/AddNewUser";
// import GstCheck from "../signupComm/GstCheck";
// import GstCheckAddCompany from "../signupComm/GstCheckAddCompany";
import GstCheckAddCompany from "../signupComm/GstCheckAddCompany";
// import AddCompanyWithNoGst from "../commodity-add-company-form/AddCompanyWithNoGst";
// import { getAdminCommUserToken } from "../../helpers";
// import { Button, Hidden } from "@mui/material";
import ExpoRedirect from "./ExpoRedirect";
// import BottomTabsNav from "./BottomTabsNav";

// import Temp from '../temp/index'

// const ProfileCompanyInfo = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/components-profile-ProfileCompanyInfo" */ "../profile/ProfileCompanyInfo"
//     );
// });
const ListingPage = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/commodity-market-page-ListingPage" */ "../commodity-market-page/ListingPage"
    );
});

const NoAdCreditPrompt = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/components-NoAdCreditPrompt" */ "../NoAdCreditPrompt"
    );
});

const VirtualOfficeProductTypes = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/components-virtualOffice-partials-VirtualOfficeProductTypes" */ "../virtualOffice/partials/VirtualOfficeProductTypes"
    );
});

// const VesselsPage = loadable(() => { return import(/* webpackChunkName: "js/chunks/components-VesselsPage" */ '../VesselsPage'); });

const MarketsPage = loadable(() => { return import(/* webpackChunkName: "js/chunks/components-VesselsPage" */ '../commodity-market-page/index'); });
// const TempMarketsPage = loadable(() => { return import(/* webpackChunkName: "js/chunks/components-VesselsPage" */ '../commodity-market-page/Temp'); });

const BrandStorePage = loadable(() => { return import(/* webpackChunkName: "js/chunks/components-BrandStorePage" */ '../brand-store/BrandStore'); });

const BrandStoreSinglePage = loadable(() => { return import(/* webpackChunkName: "js/chunks/components-BrandStorePage" */ '../brand-store/index'); });

// const CoalOriginPage = loadable(() => { return import(/* webpackChunkName: "js/chunks/components-VesselsPage" */ '../coal-origin/index'); });

const Loader = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/partials-Loader" */ "../partials/Loader"
    );
});
const LoginPage = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-LoginPage" */ "../pages/LoginPage"
        );
    },
    { fallback: <Loader /> }
);
const LogoutPage = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-LogoutPage" */ "../pages/LogoutPage"
        );
    },
    { fallback: <Loader /> }
);
// const SignupPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-SignupPage" */ "../pages/SignupPage"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const SignupPageV2 = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-SignupPage" */ "../signupV2/SignUp"
//         );
//     },
//     { fallback: <Loader /> }
// );

const SignupCommodity = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-SignupPage" */ "../signupComm/SignUp"
        );
    },
    { fallback: <Loader /> }
);
// const Newsletter = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/partials-Newsletter" */ "../partials/Newsletter"
//     );
// });
// const Footer2 = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/partials-footerv2-Footer2" */ "../partials/footerv2/Footer2"
//     );
// });
const SocialLogin = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/partials-SocialLogin" */ "../partials/SocialLogin"
        );
    },
    { fallback: <Loader /> }
);
const SellerLandingPage = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-SellerLandingPage" */ "../pages/SellerLandingPage"
        );
    },
    { fallback: <LinearProgress /> }
);
// const BuyerLandingPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-BuyerLandingPage" */ "../pages/BuyerLandingPage"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
const About = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-About" */ "../pages/About"
        );
    },
    { fallback: <LinearProgress /> }
);
const ComingSoon = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-ComingSoon" */ "../pages/ComingSoon"
        );
    },
    { fallback: <LinearProgress /> }
);
const CmsPage = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-CmsPage" */ "../pages/CmsPage"
        );
    },
    { fallback: <LinearProgress /> }
);
const NotFound404 = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/errors-NotFound404" */ "../errors/NotFound404"
        );
    },
    { fallback: <LinearProgress /> }
);
// const CompanyTransactions = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/transactions-CompanyTransactions" */ "../transactions/CompanyTransactions"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );

// const DealerRFQ = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/partials-DealerRFQ" */ "../partials/DealerRFQ"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const QuotationForm = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/send-quotation-single-page-index" */ "../send-quotation-single-page/index"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const ViewQuotationPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/view-quotation-ViewQuotationPage" */ "../view-quotation/ViewQuotationPage"
//         );
//     },
//     { fallback: <Loader /> }
// );
const Profile = loadable(
    () => {
        return import(
            "../profile/Profile"
        );
    },
    { fallback: <Loader /> }
);
// const SubUsers = loadable(
//     () => {
//         return import(
//             "../profile/subusers"
//         );
//     },
//     { fallback: <Loader /> }
// );
const VirtualOffice = loadable(
    () => {
        return import(
           "../virtualOffice/VirtualOffice"
        );
    },
    { fallback: <Loader /> }
);
// const ProductDetailsPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/view-product-details-ProductDetailsPage" */ "../view-product-details/ProductDetailsPage"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const EYardNetwork = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/eYard-Network" */ "../eYard/Network"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const EYardGlobalProfile = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/eYard-GlobalProfile" */ "../eYard/GlobalProfile"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const EYardAccessed = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/eYard-Accessed" */ "../eYard/Accessed"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const EYardGlobalProfilePerformance = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/eYard-GlobalProfilePerformance" */ "../eYard/GlobalProfilePerformance"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const EYardViewRequirementInfo = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/eYard-ViewRequirementInfo" */ "../eYard/ViewRequirementInfo"
//         );
//     },
//     { fallback: <Loader /> }
// );

const ForgotPassword = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-ForgotPassword" */ "../pages/ForgotPassword"
        );
    },
    { fallback: <LinearProgress /> }
);
// const BasisConverter = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-tools-BasisConverter" */ "../pages/tools/BasisConverter"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const BlendingCalc = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-tools-BlendingCalc" */ "../pages/tools/BlendingCalc"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const RailFreightCalc = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-tools-RailFreightCalc" */ "../pages/tools/RailFreightCalc"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const RatioCalc = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-tools-RatioCalc" */ "../pages/tools/RatioCalc"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const SlaggingFouling = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-tools-SlaggingFouling" */ "../pages/tools/SlaggingFouling"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const UnitConverter = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-tools-UnitConverter" */ "../pages/tools/UnitConverter"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const MediaKit = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/pages-MediaKit" */ "../pages/MediaKit"
//     );
// }, <LinearProgress />);
// const AboutEYard = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-about-eYard/MainPage" */ "../pages/about-eYard/MainPage"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
const ResetForgotPassword = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-ResetForgotPassword" */ "../pages/ResetForgotPassword"
        );
    },
    { fallback: <LinearProgress /> }
);
// const GuestBenchmark = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-guest-mode/benchmark/Index" */ "../pages/guest-mode/benchmark/Index"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
// const GuestMarket = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-guest-mode-markets/Index" */ "../pages/guest-mode/market/Index"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );

// const GlobalProfileNew = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/profile-global-profile-GlobalProfileNew" */ "../profile/global-profile/GlobalProfileNew"
//         );
//     },
//     { fallback: <LinearProgress /> }
// );
const Contact = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-Contact" */ "../pages/Contact"
        );
    },
    { fallback: <Loader /> }
);
const Privacy = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-Privacy" */ "../pages/Privacy"
        );
    },
    { fallback: <div>Loading Page...</div> }
);
const RefundPolicy = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-RefundPolicy" */ "../pages/RefundPolicy"
        );
    },
    { fallback: <div>Loading Page...</div> }
);
// const AwardedDealQuoteCancellation = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/pages-AwardedDealQuoteCancellation" */ "../pages/AwardedDealQuoteCancellation"
//     );
// });
const TermServices = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/pages-TermServices" */ "../pages/TermsServices"
    );
});
// const Events = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/pages-event-Events" */ "../pages/event/Events"
//     );
// });
const Events = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/pages-event-Events" */ "../pages/event/EventsMandi"
    );
});
// const IndexTab = loadable(() => {
//     return import(
//         /* webpackChunkName: "js/chunks/pages-indexes-IndexTab" */ "../pages/indexes/IndexTab"
//     );
// });
const Auction = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/pages-auctions-Auction" */ "../pages/auctions/Auction"
    );
});
const NewsDetail = loadable(
    () => {
        return import(
        "../pages/news/SingleNewsDetail"
        );
    },
    { fallback: <Loader /> }
);
// const News = loadable(
//     () => {
//         return import(
//              "../pages/news/News"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const Posts = loadable(
//     () => {
//         return import(
//             "../blog/Posts"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const SinglePost = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/blog-SinglePost" */ "../blog/SinglePost"
//         );
//     },
//     { fallback: <Loader /> }
// );

// const OperationsPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/operations-OperationButtons" */ "../operations/OperationButtons"
//         );
//     },
//     { fallback: <Loader /> }
// );
const PlanPricing = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-plan-pricing-PlanPricing" */ "../pages/plan-pricing/PlanPricing"
        );
    },
    { fallback: <Loader /> }
);
const EAuctionRegistration = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-plan-pricing-EAuctionRegistration" */ "../pages/plan-pricing/EAuctionPage"
        );
    },
    { fallback: <Loader /> }
);
const ListingFormByContract = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/add-your-products/Index" */ "../add-your-products/Index"
        );
    },
    { fallback: <Loader /> }
);
const AutoLogin = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages/AutoLogin/AutoLogin" */ "../pages/AutoLogin/AutoLogin"
        );
    },
    { fallback: <Loader /> }
);
const EAuctionTermsAndConditions = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-plan-pricing-EAuctionTermsAndConditions" */ "../pages/plan-pricing/EAuctionTermsAndConditions"
        );
    },
    { fallback: <Loader /> }
);
const JSWIndo3356GAREAuctionTermsAndConditions = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-plan-pricing-JSWIndo3356GAREAuctionTermsAndConditions" */ "../pages/plan-pricing/JSWIndo3356GAREAuctionTermsAndConditions"
        );
    },
    { fallback: <Loader /> }
);

const AtomPayResponse = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-plan-pricing-AtomPayResponse" */ "../pages/plan-pricing/AtomPayResponse"
        );
    },
    { fallback: <Loader /> }
);

const DealerSupport = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/profile-DealerSupport" */ "../profile/DealerSupport"
        );
    },
    { fallback: <Loader /> }
);

// const HelpCenter = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/profile-HelpCenter" */ "../profile/HelpCenter"
//         );
//     },
//     { fallback: <Loader /> }
// );

// const AllDispatchedVehicles = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/operations-AllDispatchedVehicles" */ "../operations/AllDispatchedVehicles"
//         );
//     },
//     { fallback: <Loader /> }
// );

const RfqSuccess = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/rfq-form-RFQSuccess" */ "../rfq-form/RFQSuccess"
        );
    },
    { fallback: <Loader /> }
);
const SellerListingSuccess = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/rfq-form-SellerListingSuccess" */ "../rfq-form/SellerListingSuccess"
        );
    },
    { fallback: <Loader /> }
);
const ProductSearchResult = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-ProductSearchResult" */ "../pages/ProductSearchResult"
        );
    },
    { fallback: <Loader /> }
);
// const BuyerMap = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/buyer-BuyerMap" */ "../buyer/BuyerMap"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const SellerMap = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/seller-SellerMap" */ "../seller/SellerMap"
//         );
//     },
//     { fallback: <Loader /> }
// );
const ListingWithoutFilter = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/buyer-ListingWithoutFilter" */ "../buyer/ListingWithoutFilter"
        );
    },
    { fallback: <Loader /> }
);
// const RFQPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-RFQPage" */ "../pages/RFQPage"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const RequirementPage = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/RequirementPage" */ "../RequirementPage"
//         );
//     },
//     { fallback: <Loader /> }
// );
const RequirementPageNew = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/RequirementPage" */ "../RequirementPageNew"
        );
    },
    { fallback: <Loader /> }
);
const Notifications = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/virtualOffice-notifications-Notifications" */ "../NotificationPage"
        );
    },
    { fallback: <Loader /> }
);
const Wallet = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/wallet-Wallet" */ "./../wallet/Wallet"
        );
    },
    { fallback: <Loader /> }
);
const DealsAndTrades = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/wallet-Wallet" */ "./../deals-and-trades/Index"
        );
    },
    { fallback: <Loader /> }
);
const OpenMarket = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/wallet-Wallet" */ "./../user-open-market/Index"
        );
    },
    { fallback: <Loader /> }
);
const ReferenceList = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/ReferenceList" */ "../ReferenceList"
        );
    },
    { fallback: <Loader /> }
);
const BlockedCompanies = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/BlockedCompanies" */ "../BlockedCompanies"
        );
    },
    { fallback: <Loader /> }
);
// const Faq = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/pages-faq-Faq" */ "../pages/faq/Faq"
//         );
//     },
//     { fallback: <Loader /> }
// );
// const AddCompanyNew = loadable(
//     () => {
//         return import(
//             /* webpackChunkName: "js/chunks/add-company-form-MainForm" */ "../add-company-form-V2/MainForm"
//         );
//     },
//     { fallback: <Loader /> }
// );

const AddCompanyCommodity = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/add-company-form-MainForm" */ "../commodity-add-company-form/MainForm"
        );
    },
    { fallback: <Loader /> }
);
const AddCompanySuccess = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/add-company-form-CompanyKycSuccess" */ "../commodity-add-company-form/CompanyKycSuccess"
        );
    },
    { fallback: <Loader /> }
);
const EKycNew = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/eKyc-MainForm" */ "../eKyc/MainForm"
        );
    },
    { fallback: <Loader /> }
);
const EKycSuccess = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/eKyc-eKycSuccess" */ "../eKyc/eKycSuccess"
        );
    },
    { fallback: <Loader /> }
);
const SendQuotationSuccess = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/send-quotation-form-QuotationSuccess" */ "../send-quotation-form/QuotationSuccess"
        );
    },
    { fallback: <Loader /> }
);
const VerifyEmail = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/pages-VerifyEmail" */ "../pages/VerifyEmail"
        );
    },
    { fallback: <Loader /> }
);
const OtpVerification = loadable(
    () => {
        return import(
            /* webpackChunkName: "js/chunks/signup-OtpVerification" */ "../signup/OtpVerification"
        );
    },
    { fallback: <Loader /> }
);
const LPFooter = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/partials-LPFooter" */ "./LPFooter"
    );
});

const MarketsSelect = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/markets-Index" */ "../markets/Index"
    );
});

const MobileViewSingleBenchmark = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/virtualOffice-benchmarks-MobileViewSingleBenchmark" */ "../virtualOffice/benchmarks/MobileViewSingleBenchmark"
    );
});

const RoutesContainer = posed.div({
    // enter: {
    // 	opacity: 1,
    // 	transition: { duration: 500 },
    // 	beforeChildren: true
    // },
    // exit: { opacity: 0, }
});

const connectWebSocket = connectWS;

function SwitchRoutes(props) {
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
    const isAdminLoggedIn = useSelector(state => state.consoleUserReducer.isLoggedIn);
    const user = useSelector((state) => state.userReducer.user);
    const locationHook = useLocation()
    const location = useLocation()
    const navigate = useNavigate();
    const [showOvershootQuantityDialog, showOvershootQuantityDialogSet] = React.useState({ show: false, data: {} })
    const pathSplit = location.pathname.split("/")

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [locationHook.pathname])

    // React.useEffect(() => {
    //     OneSignal.init({
    //         appId: process.env.REACT_APP_ONESIGNAL_APPID,
    //         safari_web_id: "web.onesignal.auto.47e61a84-7486-476e-83ad-85400aafc6ca",
    //         notifyButton: {
    //             enable: true,
    //         },
    //         subdomainName: "phoenix-stagin",
    //     });
    // }, [])
    
    React.useEffect(() => {
        Emitter.on("SNACKBAR_NOTIFICATION", (e) => {
            props.enqueueSnackbar(e.message, e.params)
        });

        Emitter.on("OVERSHOOT_QUANTITY", (e) => {
            showOvershootQuantityDialogSet({ show: true, data: e })
        });

        return function cleanUp() {
            Emitter.off("SNACKBAR_NOTIFICATION");
        };
    }, [])

    React.useEffect(() => {
        if (window.location.pathname.includes('add-listings')) {
            navigate('/requirements/buyer')
        }
        // ReactGA.pageview(locationHook.pathname + locationHook.search);
        ReactGA.send({ hitType: "pageview", page: locationHook.pathname + locationHook.search });
        showOvershootQuantityDialogSet({ show: false, data: {} })
        if (window.Hansel) {
            window.Hansel.logEvent('web_page_browse', 'fbs', { 'pageURL': window.location.href });
        }
    }, [locationHook])

    // React.useEffect(() => {
    //     const adminUserToken = getAdminCommUserToken('commconsole');
    //     if(adminUserToken) {
    //         setAdminToken(adminUserToken);
    //     }
    // }, [])

    // React.useEffect(() => {
    //     if(adminToken) {
    //         const extraHeadersAdmin = { Authorization: `Bearer ${adminToken}`, isadmin: true };
    //         connectWS('admin', extraHeadersAdmin);
    //     }
    // }, [adminToken])

    React.useEffect(() => {
        if(isLoggedIn) {
            connectWebSocket();
            socket.on('userStatus', (data) => {
                console.log('user live status', {data, status:data.status, user: data.userId});
                // updateUserStatus(data.userId, data.status);
            });
            socket.on('userBlocked', (data) => {
                console.log('socket user blocked')
                if(data.status === 1) {
                    console.log('logging out userrr')
                    localStorage.removeItem('comm_user_token');
                    navigate('/login')
                }
            })
        }

        return () => {
            socket.disconnect();
            socket.off('userStatus');
            socket.off('userBlocked');
        }
    }, [isLoggedIn])


    return (
        <React.Fragment>
            {(pathSplit && (pathSplit.length === 3 && pathSplit[1] === "brand-stores")) ? <NavbarBrandStore /> : <NavbarV3 />}

            {/* Add References - Do not show on self url */}
            {/* {(pathSplit && (pathSplit.length === 3 && pathSplit[1] === "brand-stores")) ? '' : (isLoggedIn && location.pathname !== '/my-safety-circle') && <PromptAddReference />} */}

            {/* {isLoggedIn && <ProfileCompanyInfo />} */}
            {(showOvershootQuantityDialog && showOvershootQuantityDialog.show) &&
                <NoAdCreditPrompt
                    data={showOvershootQuantityDialog.data}
                    open={showOvershootQuantityDialog.show}
                    onChangeOpen={e => showOvershootQuantityDialogSet({ show: e, data: showOvershootQuantityDialog.data })}
                />
            }
            <PoseGroup>
                <RoutesContainer key={location.pathname}>
                    <Routes>
                        <Route 
                            path="/expo"
                            element={<OnLoadTransition transition="fade" timeout={config.user_page_transition_timeout} key={location.pathname}>
                                <ExpoRedirect />
                            </OnLoadTransition>}
                        />
                        {/* <Route
                            path="/requirements/:type"
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <RequirementPageNew location={location} />
                                </OnLoadTransition>
                            }
                        //element={RequirementPage}
                        /> */}
                        {/* <Route
                            path="/requirements-new/:type"
                            element={RequirementPageNew}
                        /> */}
                        {/* <Route path="/global-ratings/:company_id/:user_mode" element={<GlobalProfileNew />} /> */}
                        {/* <Route path="/global-ratings" element={GlobalProfileNew} /> */}
                        {/* <Route path="/transactions" element={<CompanyTransactions />} /> */}
                        {/* <Route path="/brand-store-demo" element={<BrandStoreLandingPage/>}/> */}
                        <Route path="/listings/:slug" element={<ListingPage/>}/>
                        {/* <Route path="/requirement/:type" element={<AuthRoute><RFQPage /></AuthRoute>} /> */}

                        <Route path="/rfq-success" element={<RfqSuccess />} />
                        <Route path="/seller-listing-success" element={<SellerListingSuccess />} />

                        {/* <Route path="/add-company-eKyc" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <AddCompanyNew />
                            </OnLoadTransition>
                        </AuthRoute>} /> */}

                        <Route path="/add-company-eKyc" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <AddCompanyCommodity />
                            </OnLoadTransition>
                        </AuthRoute>} />
                        {/* <Route path="/add-company-eKyc-noGst" element={<AuthRoute>
                            <AddCompanyWithNoGst />
                        </AuthRoute>} /> */}



                        <Route path="/company-added" element={<AuthRoute>
                            <AddCompanySuccess />
                        </AuthRoute>} />

                        <Route path="/eKyc" element={<AuthRoute>
                            <EKycNew />
                        </AuthRoute>} />

                        <Route path="/eKyc-success" element={<AuthRoute>
                            <EKycSuccess />
                        </AuthRoute>} />

                        {/* <AuthRoute
                            exact
                            path="/profile/help-center"
                        >
                            <HelpCenter />
                        </AuthRoute> */}

                        {/* <Route path="/profile/support-assigned-dealer" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <DealerSupport />
                            </OnLoadTransition>
                        </AuthRoute>} /> */}

                        {/* <Route path="/send-quotation/:product_id/:communication_id" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <QuotationForm />
                            </OnLoadTransition>
                        </AuthRoute>} />
                        <Route path="/send-quotation-success" element={<AuthRoute>
                            <SendQuotationSuccess />
                        </AuthRoute>} />
                        <Route path="/view-quotation/:quotation_id" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <ViewQuotationPage />
                            </OnLoadTransition>
                        </AuthRoute>} />
                        <Route path="/view-product-details/:listing_id" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <ProductDetailsPage />
                            </OnLoadTransition>
                        </AuthRoute>} /> */}

                        {/* <Route path="/profile/sub-users" element={<AuthRoute><SubUsers /></AuthRoute>}> */}


                        <Route path="/profile" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <Profile />
                            </OnLoadTransition>
                        </AuthRoute>} />

                        <Route path="/dashboard/benchmarks/:benchmarkId/show/m" element={<AuthRoute>
                            <MobileViewSingleBenchmark />
                        </AuthRoute>} />
<Route path="/addCompany/gstCheck" element={<AuthRoute><GstCheckAddCompany/></AuthRoute>}/>
                        <Route path="/dashboard" element={<AuthRoute><VirtualOffice /></AuthRoute>} >
                            <Route path=":type" element={<VirtualOfficeProductTypes />} />
                        </Route>
                        <Route
                        path="/addUser"
                       element={<AddNewUser/>}

                        >

                        </Route>

                        {/* <Route path="/markets/select-markets" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <MarketsSelect />
                            </OnLoadTransition>
                        </AuthRoute>} /> */}

                        <Route
                            path="/listings"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <ListingWithoutFilter location={location} />
                                </OnLoadTransition>
                            }
                        />

                        <Route path="/reset-password" element={<ResetForgotPassword />} />
                        <Route path="/about-us" element={<About />} />
                        {/* <Route path="/guest/markets" element={<GuestMarket />} /> */}
                        <Route path="/coming-soon" element={<ComingSoon />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/privacy-policy" element={<Privacy />} />
                        <Route path="/refund-policy" element={<RefundPolicy />} />
                        {/* <Route path="/awarded-deal-quote-cancellation-policy" element={<AwardedDealQuoteCancellation />} /> */}
                        <Route path="/term-services" element={<TermServices />} />
                        <Route path="/auction" element={<Auction />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/commodities/select-commodities" element={<SelectCommodities/>}/>

                        {/* <Route path="/faqs" element={Faq} /> */}
                        {/* <Route
                            path="/pricing-plans"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <PlanPricing location={location} />
                                </OnLoadTransition>
                            }
                        />
                        <Route
                            path="/jsw-indo-eauction-3356-gar-202303-1"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <JSWIndo3356GAREAuctionTermsAndConditions location={location} />
                                </OnLoadTransition>
                            }
                        />
                        <Route
                            path="/eauction-registration"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <EAuctionTermsAndConditions location={location} />
                                </OnLoadTransition>
                            }
                        />
                        <Route
                            path="/eauctions"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <EAuctionRegistration location={location} />
                                </OnLoadTransition>
                            }
                        /> */}

                        <Route
                            path="/add-your-products"
                            exact
                            element={
                                <AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <ListingFormByContract location={location} />
                                </OnLoadTransition>
                                </AuthRoute>
                            }
                        />
                        {/* <Route
                            path="/alr"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <AutoLogin location={location} />
                                </OnLoadTransition>
                            }
                        /> */}
                        {/* <Route
                            path="/atom-pay/response"
                            exact
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <AtomPayResponse location={location} />
                                </OnLoadTransition>
                            }
                        /> */}

                        <Route path="/notifications" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <Notifications />
                            </OnLoadTransition>
                        </AuthRoute>} />

                        {/* <Route path="/my-wallet" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <Wallet />
                            </OnLoadTransition>
                        </AuthRoute>} /> */}

                        <Route path="/deals-and-trades" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <DealsAndTrades />
                            </OnLoadTransition>
                        </AuthRoute>} />

                        <Route path="/re-post" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <OpenMarket />
                            </OnLoadTransition>
                        </AuthRoute>} />

                        {/* <Route path="/my-safety-circle" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <ReferenceList />
                            </OnLoadTransition>
                        </AuthRoute>} /> */}

                        {/* <Route path="/blocked-companies" element={<AuthRoute>
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <BlockedCompanies />
                            </OnLoadTransition>
                        </AuthRoute>} />

                        <Route path="/guest/benchmark" element={<GuestBenchmark />} />

                        <Route path="/global-indexes" element={<IndexTab />} />
                        <Route path="/blog" element={<Posts />} />
                        <Route path="/blog/:slug" element={<SinglePost />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/news/:slug" element={<NewsDetail />} />
                        <Route path="/media-kit" element={<MediaKit />} />
                        <Route path="/about-eYard" element={<AboutEYard />} /> */}

                        {/* <Route path="/tools/basis-converter" element={<BasisConverter />} />
                        <Route path="/tools/blending-calculator" element={<BlendingCalc />} />
                        <Route path="/tools/rail-freight-calculator" element={<RailFreightCalc />} />

                        <Route path="/tools/ratio-calculator" element={<RatioCalc />} />
                        <Route path="/tools/slagging-and-fouling" element={<SlaggingFouling />} />
                        <Route path="/tools/unit-converter" element={<UnitConverter />} /> */}

                        <Route path="/social/:provider/login/callback" element={<SocialLogin />} />
                        <Route
                            path="/login"
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <LoginPage location={location} />
                                </OnLoadTransition>
                            }
                        />
                        {/* <Route
                            path="/sign-up"
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <SignupPage location={location} />
                                </OnLoadTransition>
                            }
                        /> */}
                        {/* <Route
                            path="/sign-up/:contact_number"
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <SignupPageV2 location={location} />
                                </OnLoadTransition>
                            }
                        /> */}
                         <Route
                            path="/sign-up/:contact_number"
                            element={
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <SignupCommodity location={location} />
                                </OnLoadTransition>
                            }
                        />
                        <Route path="/verify-otp" element={<OtpVerification />} />

                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/product-search" element={<ProductSearchResult />} />
                        <Route path="/email-verification/:token" element={<VerifyEmail />} />
                        {/* <Route
                            path="/slwcut"
                            exact
                            element={DealerRFQ}
                        /> */}

                        {/* operations routes */}
                        {/* <Route path="/operations" element={<AuthRoute><OperationsPage /></AuthRoute>} /> */}
                        {/* <Route path="/operations/dispatched-vehicles" element={<AuthRoute><AllDispatchedVehicles /></AuthRoute>} /> */}
                        {/* <Route path="/operations/:product_type/schedule-truck/:award_id" element={<AuthRoute><ScheduleTruck/></AuthRoute>} />
                    <Route path="/operations/:product_type/payment-confirm" element={<AuthRoute><PaymentConfirm/></AuthRoute>} />
                    <Route path="/operations/:product_type/add-vehicle/:award_id" element={<AuthRoute><AddVehicle/></AuthRoute>} /> */}
                        <Route path="/brand-stores" element={<BrandStorePage />} />
                        <Route path="/brand-stores/:slug" element={<BrandStoreSinglePage />} />
                        {/* <Route path="/view-company-badges" element={<CompanyBadges />} /> */}
                        <Route path="/seller" element={<SellerLandingPage />} />

                        {/* <Route path="/temp" element={<Temp />} /> */}

                        {/* <Route path="/markets/:market_code" exact element={isLoggedIn ?
                            <AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <MarketsPage />
                                </OnLoadTransition>
                            </AuthRoute>
                            : <GuestMarket />
                        }
                        /> */}
                        {/* <Route path="/markets" exact element={isLoggedIn ?
                            <AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <MarketsPage />
                                </OnLoadTransition>
                            </AuthRoute>
                            : <GuestMarket />
                        }
                        /> */}

                        {/* <Route
                            path="/markets"
                            exact
                            element={<AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <MarketsPage />
                                </OnLoadTransition>
                            </AuthRoute>}
                        />
                        <Route
                            path="/markets/:contractSlug"
                            exact
                            element={<AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <MarketsPage />
                                </OnLoadTransition>
                            </AuthRoute>}
                        /> */}

                        {/* <Route
                            path="/mmarkets/*"
                            element={<TempMarketsPage />}
                        /> */}

                        <Route
                            path="/markets"
                            // exact
                            element={
                            // <AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <MarketsPage />
                            </OnLoadTransition>
                            // </AuthRoute>
                            }
                        >
                            {/* <Route
                                path="/markets/:commoditySlug"
                                // exact
                                element={<AuthRoute>
                                    <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <MarketsPage />
                                </OnLoadTransition>
                                </AuthRoute>}
                            > */}
                                {/* removing contract param to use it as a query instead of url parameter. changed on 24 june 2024 */}
                                {/* <Route
                                    path="/markets/:commoditySlug/:contractCode"
                                    // exact
                                    element={<AuthRoute>
                                        <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                        <MarketsPage />
                                    </OnLoadTransition>
                                    </AuthRoute>}
                                >
                                </Route> */}
                            {/* </Route> */}
                        </Route>


                        {/* <Route path="/coal-origin" exact element={isLoggedIn ?
                            <AuthRoute>
                                <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                    <CoalOriginPage />
                                </OnLoadTransition>
                            </AuthRoute>
                            : <GuestMarket />
                        }
                        /> */}

                        {/* <Route path="/buyer" element={<BuyerLandingPage />} /> */}
                        <Route path="/logout" element={<LogoutPage />} />
                        <Route path="/" element={
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <Homepage location={location} />
                            </OnLoadTransition>
                        }
                        />

                        {/* {this.props.isLoggedIn === false && <Newsletter />} */}

                        <Route path="/404" element={<NotFound404 />} />
                        <Route path="/:slug" element={<CmsPage />} />
                        <Route path="*" element={
                            <OnLoadTransition transition="fade" timemout={config.user_page_transition_timeout} key={location.pathname}>
                                <NotFound404 location={location} />
                            </OnLoadTransition>
                        }
                        />
                    </Routes>
                </RoutesContainer>
            </PoseGroup>
            {/* {(pathSplit && (pathSplit.length === 3 && pathSplit[1] === "brand-stores")) ? <Box style={{ backgroundColor: '#2959BA', padding: 5, textAlign: 'center', color: '#fff', marginTop: '100px' }}>
                Powered By CoalShastra
            </Box> : location.pathname === "/seller" ||
                location.pathname === "/view-company-badges" ||
                location.pathname === "/brand-store-demo" ||
                location.pathname === "/buyer" ? (
                <LPFooter />
            ) : (
                <Footer2 />



            )} */}
        </React.Fragment>
    );
}

// const mapStateToProps = state => {
//     return {
//         user: state.userReducer.user,
//         isLoggedIn: state.userReducer.isLoggedIn,
//         loginStatus: state.userReducer.loginStatus,
// 		isLoading: state.commonReducer.loader
//     };
// };

// export default connect(mapStateToProps, { })(SwitchRoutes);

export default withSnackbar(SwitchRoutes);
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import { cDecrypt } from "../../helpers";
import { Button } from "@mui/material";

export default function ExpoRedirect() {
    const location = useLocation();
    // const params = useParams();
    const params = new URLSearchParams(location.search);
    // const expoToken = params.token;
    const token =  params.get('token')
    const expoToken = cDecrypt(token);
    const navigate = useNavigate();

    React.useEffect(() => {
        if(expoToken)
            localStorage.setItem('expoToken', expoToken);
        
        navigate("/");
    }, []);
    
    return <Loader />
}
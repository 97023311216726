import React from 'react'
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import InputAdornment from '@mui/material/InputAdornment';
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/Info';
import { getDeliveryTermName, validatorRegEx } from "../../../helpers";
import LightTooltip from "../../partials/LightTooltip";
import { useSelector } from 'react-redux'
import { User } from "../../../api/resources/User"
import ImageUploadingInput from '../../ImageUploadingInput';
import { Autocomplete } from '@mui/material';

export default function ChatBidAndOfferForm(props) {
//   console.log('props final', props)
    const formEl = React.useRef(null);
    const user = useSelector(state => state.userReducer.user);
    const tickSize = useSelector(state => state.commonReducer.globalSettings.tick_size);
    const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const paymentTerms = useSelector(state => state.commonReducer.paymentTerms);
    const [price, priceSet] = React.useState();
    const [quantity, quantitySet] = React.useState();
    const [lifting, liftingSet] = React.useState();
    const [paymentMode, paymentModeSet] = React.useState('');
    const [paymentModeId, paymentModeIdSet] = React.useState('');
    const [deliveryTerm, deliveryTermSet] = React.useState(null);
    const [deliveryTermId, deliveryTermIdSet] = React.useState('2');
    const [comment, commentSet] = React.useState('');
    const [advance, advanceSet] = React.useState();
    const [images, imagesSet] = React.useState([]);
    const [balance, balanceSet] = React.useState(null);
    const [creditDaysNumbers, creditDaysNumbersSet] = React.useState('30');
    const [paymentDays, paymentDaysSet] = React.useState('');
    const [validityTime, validityTimeSet] = React.useState(30);
    const [showCommentField, showCommentFieldSet] = React.useState(false);
    const [modification, modificationSet] = React.useState(false);
    const [isValidPrice, setIsValidPrice] = React.useState(true);
    const [isValidQuantity, setIsValidQuantity] = React.useState(true);
    const [minValidQuantity, minValidQuantitySet] = React.useState(25)
    const [maxValidQuantity, maxValidQuantitySet] = React.useState(99999)
    const [settlementInputValue, setSettlementInputValue] = React.useState([])
    const [localSettlementFields, setLocalSettlementFields] = React.useState(props.settlementFields || []);
    const [counterSettlementFieldsArray, setCounterSettlementFieldsArray]=React.useState(props.counterSettlementFields?Object.entries(props.counterSettlementFields).map(([name,value]) =>({
        name,value
    })):[])

    React.useEffect(() => {
        // console.log({ props })
        // console.log({ counterSettlementFieldsArray })
        priceSet(props.price ? props.price : '');
        quantitySet(props.quantity);
        liftingSet(props.liftingDays ? props.liftingDays : '');
        paymentModeIdSet(props.paymentTermId ? props.paymentTermId : '');
        advanceSet(props.ccPercentage ? props.ccPercentage : '10');
        creditDaysNumbersSet(props.creditDays == 0 ? 0 : props.creditDays ? props.creditDays : '30');
        paymentDaysSet(props.paymentDays != null ? props.paymentDays : props.liftingDays ? props.liftingDays : '')
        commentSet(props.specialRequest ? props.specialRequest : '');
    }, [props.price, props.quantity, props.liftingDays, props.paymentTermId, props.ccPercentage, props.creditDays, props.specialRequest, props.paymentDays, props.brandStore])


    React.useEffect(() => {
        if (quantity && props.quantityTickSize) {
            if (quantity % props.quantityTickSize === 0) {
                setIsValidQuantity(true)
            }
            if (quantity % props.quantityTickSize !== 0) {
                setIsValidQuantity(false)
            }
        }
    }, [quantity, props.quantityTickSize])

    React.useEffect(() => {
        if (props.quantity && props.quantityRangeMin && props.minimumOrderQuantity) {
            let calcMinQty = props.quantity * props.quantityRangeMin
            calcMinQty > props.minimumOrderQuantity ? minValidQuantitySet(calcMinQty) : minValidQuantitySet(props.brandStore == true ? 25 : props.minimumOrderQuantity)
        }
    }, [props.quantity, props.quantityRangeMin, props.minimumOrderQuantity, props.brandStore])

    React.useEffect(() => {
        if (props.quantity && props.quantityRangeMax && props.quantityRangeMax != '') {
            maxValidQuantitySet(props.brandStore == true ? 99999 : props.quantity * props.quantityRangeMax)
        }
    }, [props.quantity, props.quantityRangeMax, props.brandStore])



    const handleSettlementFieldsChange = (index, value) => {
        const updatedSettlementFields = [...localSettlementFields];
        updatedSettlementFields[index].value = value;
        setLocalSettlementFields(updatedSettlementFields);
        props.settlementFieldsSet(updatedSettlementFields); // Update the parent component's state
    };

    const handleCounterSettlementFieldsChange = (index, value) => {
        const updatedCounterSettlementFields = [...counterSettlementFieldsArray];
        updatedCounterSettlementFields[index].value = value;
        setCounterSettlementFieldsArray(updatedCounterSettlementFields);
    };

    return <ValidatorForm
        ref={formEl}
        className="messageForm"
        onSubmit={e => {
            // console.log({localSettlementFields,counterSettlementFieldsArray})
            const data = {
                // console.log({localSettlementFields,counterSettlementFieldsArray}),
                price: parseFloat(price),
                quantity: quantity,
                lifting: lifting, paymentMode: paymentMode,
                paymentModeId: paymentModeId,
                advance: advance,
                balance: balance,
                creditDays: creditDaysNumbers,
                paymentDays: paymentDays,
                deliveryTerm: deliveryTerm,
                deliveryTermId: props?.deliverytermsArray[0]?._id,
                validityTime: validityTime,
                comment: comment,
                images: images,

                settlementFields: localSettlementFields.length > 0 ? localSettlementFields : counterSettlementFieldsArray,
            }
            // console.log({dataChatBid:data})
            props.onSubmit(data)
        }
        }
        noValidate
    >
        <Grid container spacing={2}>
         {/* <Button onClick={()=>console.log(localSettlementFields)}>click</Button>
         <Button onClick={()=>console.log(counterSettlementFieldsArray)}>filkdss</Button> */}
         
            <Grid item xs={12} >


                <TextValidator variant="outlined" required fullWidth size="small" id="price" autoComplete="price" autoFocus
                    label={`${props.currency == 'INR' ? 'Price' : 'Price'}`} name="price" value={price}
                    validators={[
                        "required",
                        // "isFloat",
                        `maxNumber:${parseInt(9999999)}`,
                        "isPositive",
                        //`matchRegexp:${validatorRegEx(tickSize == 25 ? "multiple25" : "endsWith", [0, 5])}`,
                        //`matchRegexp:${validatorRegEx("nonZero")}`
                    ]}
                    errorMessages={[
                        "Price is required.",
                        // "Only numeric values accepted.",
                        "Price value can not be greater than 7 digits.",
                        "Price cannot be in negative numbers!",
                        //(props.reOffer || props.product_type == 1) ? `Please enter Offer Price as multiple of ${tickSize == 25 ? "25" : "5"}` : `Please enter Required Price as multiple of ${props.tickSize == 25 ? "25" : "5"}`,
                        //"Price value can not be Zero !!"
                    ]}
                    onChange={e => { priceSet(e.target.value); modificationSet(parseFloat(props.price) == parseFloat(e.target.value) ? false : true); }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            {props.currency}
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">{props?.contractData?.currency}/{props?.contractData?.quantityUnit?.name}</InputAdornment>
                    }}
                />
                {/* {isValidPrice === false && (
                    <Typography
                        component="p"
                        color="error"
                        className="has__error"
                        style={{ margin: '0 14px' }}
                    >
                        Please enter Price as multiple of {props.priceTickSize}.
                    </Typography>
                )} */}

                {/* {multiple25Error && (
                    <Box mt={1}>
                        <Typography component="p" color="error" className="has__error">
                            {(props.reOffer || props.product_type == 1) ? "Please enter Offer Price as multiple of 25" : "Please enter Required Price as multiple of 25"}
                        </Typography>
                    </Box>
                )} */}
            </Grid>
            <Grid item xs={12} >
                <TextValidator variant="outlined" required fullWidth size="small" id="quantity" autoComplete="quantity"
                    label="Quantity" name="quantity" value={quantity}
                    validators={[
                        "required",
                        "isNumber",
                        `maxNumber:${parseInt(9999999)}`,
                        `minNumber:${minValidQuantity}`,
                        `maxNumber:${maxValidQuantity}`
                    ]}
                    errorMessages={[
                        "Quantity is required.",
                        "Only numeric values accepted.",
                        "Quantity value can not be greater than 7 digits.",
                        `Quantity value cannot be less than ${minValidQuantity}MT`,
                        `Quantity value cannot be greater than ${maxValidQuantity}MT`
                    ]}
                    onChange={e => { quantitySet(e.target.value); modificationSet(props.quantity == e.target.value ? false : true); }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{props?.contractData?.quantityUnit?.name}</InputAdornment>
                    }}
                />
                {isValidQuantity === false && (
                    <Typography
                        component="p"
                        color="error"
                        className="has__error"
                        style={{ margin: '0 14px' }}
                    >
                        Please enter Quantity as multiple of {props.quantityTickSize}.
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <TextValidator variant="outlined" required fullWidth size="small" id="lifting" autoComplete="lifting"
                    label="Lifting Days" name="lifting" value={lifting}
                    validators={["required", "isNumber", `matchRegexp:${validatorRegEx("nonZero")}`]}
                    errorMessages={["Lifting days is required.", "Only numeric values accepted.", "Lifting Days can not be Zero !!"]}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>
                    }}
                    onChange={e => { liftingSet(e.target.value); modificationSet(props.liftingDays == e.target.value ? false : true); }}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectValidator native="true" required variant="outlined" size="small" fullWidth
                    value={paymentModeId} name="paymentModeId" label="Payment Mode"
                    validators={["required"]}
                    errorMessages={["Payment mode is required"]}
                    onChange={e => { paymentModeIdSet(e.target.value); modificationSet(props.paymentTermId == e.target.value ? false : true); }}
                >
                    {(props?.contract && props?.contract.length > 0) && props?.contract.map((p, key) => (
                        <MenuItem
                            value={p._id}
                            key={key}
                        >
                            {p.name}
                        </MenuItem>
                    ))}
                    {/* <MenuItem value='1'>Advance</MenuItem>
                    <MenuItem value='3'>Credit</MenuItem>
                    <MenuItem value='4'>Cash &amp; Carry</MenuItem> */}
                </SelectValidator>
            </Grid>

            {(paymentModeId == 4) && <Grid item xs={6}>
                <TextValidator variant="outlined" required fullWidth size="small" id="advance" autoComplete="advance"
                    label="Advance" name="Advance" value={advance}
                    validators={["required", "isNumber", `maxNumber:${parseInt(100)}`,]}
                    errorMessages={["Advance is required.", "Only numeric values accepted.", "Advance value can not be greater than 100",]}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    onChange={e => {
                        advanceSet(e.target.value);
                        modificationSet(props.ccPercentage == e.target.value ? false : true);
                        if (e.target.value >= 0) {
                            balanceSet(100 - e.target.value)
                        }
                    }
                    }
                />
            </Grid>}
            {(paymentModeId == 4) && <Grid item xs={6}>
                <TextValidator variant="outlined" required fullWidth size="small" id="balance" autoComplete="balance"
                    label="Balance" name="Balance" value={balance}
                    validators={["required", "isNumber"]}
                    errorMessages={["Balance is required.", "Only numeric values accepted."]}
                    disabled
                    readOnly
                    InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>}
            {(paymentModeId == 2 || paymentModeId == 3) && <Grid item xs={12}>
                <TextValidator variant="outlined" required fullWidth size="small" id="creditDaysNumbers" autoComplete="creditDaysNumbers"
                    label="No. of Credit Days" name="No. of Credit Days" value={creditDaysNumbers}
                    validators={["required", "isNumber", `minNumber:${0}`]}
                    errorMessages={["No. of Credit Days is required.", "Only numeric values accepted.", "Credit Days can not be Negative !!"]}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>
                    }}
                    onChange={e => { creditDaysNumbersSet(e.target.value); modificationSet(props.creditDays == e.target.value ? false : true); }}
                />
            </Grid>}
            {paymentModeId == 4 &&
                <Grid item xs={12}>
                    <TextValidator variant="outlined" required fullWidth size="small" id="paymentDaysNumbers" autoComplete="paymentDaysNumbers"
                        label="No. of Payment Days" name="No. of Payment Days" value={paymentDays}
                        validators={["required", "isNumber", `matchRegexp:${validatorRegEx("nonZero")}`]}
                        errorMessages={["No. of Payment Days is required.", "Only numeric values accepted.", "Payment Days can not be Zero !!"]}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Days</InputAdornment>
                        }}
                        onChange={e => { paymentDaysSet(e.target.value); modificationSet(props.paymentDays == e.target.value ? false : true); }}
                    />
                </Grid>}

                <Grid item sm={12} >
       <Box style={{display:"flex",justifyContent:"space-evenly",flexDirection:"column"}}>
       {localSettlementFields && localSettlementFields.map((item, index) => (
        <>
            {
                item?.inputType === 'Dropdown' ? 
                <Autocomplete
                name="SettlementField_name"
                size="small"
                filterSelectedOptions
                options={
                    item?.multipleValues 
                }
                onChange={(e, newVal) => {
                    handleSettlementFieldsChange(index, newVal)
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => <Box component="li" {...props} key={option}>{option}</Box>}
                value={item.value || null}
                renderInput={params => (
                    <TextValidator
                        {...params}
                        variant="outlined"
                        label= 
                            <span style={{ fontSize: '13px'}}>{item.name} {item?.units[0]?.name || ''}</span>
                        fullWidth
                    />
                )}
            /> :

            <TextValidator
            style={{marginBottom:5}}
                key={index}
                variant="outlined"
                required
                fullWidth
                size="small"
                id={`settlementField-${index}`}
                name={item.name}
                value={item.value}
                autoComplete={`settlementField-${index}`}
                autoFocus
                label={item.name}
                validators={[
                    "required",
                ...(typeof item.value === 'number' ? [
                    'isNumber', 
                    'maxNumber', 
                    'isPositive'] 
                    : [])
                ]}
                errorMessages={[
                    "SettlementField is required.",
                ...(typeof item.value === 'number' ? [
                    "Only numeric values are allowed",
                    "SettlementField value cannot be greater than 7 digits.",
                    "SettlementField cannot be in negative numbers!",] 
                    : [])
                ]}
                onChange={(e) => handleSettlementFieldsChange(index, e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {item?.units[0]?.name}
                        </InputAdornment>
                    ),
                }}
            />
            }
            <br/>
        </>
    ))}
       </Box>
        <Box style={{display:"flex",justifyContent:"space-evenly",flexDirection:"column"}}>
{
counterSettlementFieldsArray &&
counterSettlementFieldsArray.map((item,index)=>(
    <>
 {
    props.settlementUnits[index] !== null && props.settlementUnits[index].inputType === "Dropdown"
    ?
    <Autocomplete
                name="SettlementField_name"
                size="small"
                filterSelectedOptions
                options={
                    props.settlementUnits[index]?.multipleValues 
                }
                onChange={(e, newVal) => {
                    handleCounterSettlementFieldsChange(index, newVal)
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => <Box component="li" {...props} key={option}>{option}</Box>}
                value={item.value || null}
                renderInput={params => (
                    <TextValidator
                        {...params}
                        variant="outlined"
                        label= 
                            <span style={{ fontSize: '13px'}}>{props.settlementUnits[index].name + ' - ' + props.settlementUnits[index]?.units[0]?.name}</span>
                        fullWidth
                    />
                )}
            />
    :  
     <TextValidator
     style={{marginBottom:5,marginTop:5}}
     key={index}
     variant="outlined"
     required
     fullWidth
     size="small"
     id={`settlementField-${index}`}
     name={item.name}
     value={item.value}
     autoComplete={`settlementField-${index}`}
     autoFocus
     label={item.name}
     validators={[
         "required",
        ...(typeof item.value === 'number' ? [
            'isNumber', 
            'maxNumber', 
            'isPositive'] 
            : [])
     ]}
     errorMessages={[
         "SettlementField is required.",
        ...(typeof item.value === 'number' ? [
            "Only numeric values are allowed",
            "SettlementField value cannot be greater than 7 digits.",
            "SettlementField cannot be in negative numbers!",] 
            : [])
     ]}
     onChange={(e) => handleCounterSettlementFieldsChange(index, e.target.value)}
     InputProps={{
         endAdornment: (
             <InputAdornment position="end">
                 {props.settlementUnits.map((unit, unitIndex) =>(
                   index === unitIndex && unit?.units[0]?.name
                 ) )}
             </InputAdornment>
         ),
     }}
 />
}
<br />
</>
)
)}
        </Box>
                </Grid>
                
            <Grid item xs={12}>
                <FormLabel component="legend">
                    {(props.reOffer || props.product_type == 1) ? "Offer Validity" : "Bid Validity"} <sup style={{ color: "red" }}>*</sup>
                    <LightTooltip
                        arrow
                        title={`The time after which the ${(props.reOffer || props.product_type == 1) ? "offer" : "bid"} will automatically be withdrawn from the buyer`}
                    >
                        <InfoIcon fontSize='24' />
                    </LightTooltip>
                </FormLabel>
                <SelectValidator native="true" required variant="outlined" size="small" fullWidth
                    value={validityTime} name="validityTime"
                    validators={["required"]}
                    errorMessages={[((props.reOffer || props.product_type == 1) ? "Offer Validity" : "Bid Validity") + " is required"]}
                    onChange={e => { validityTimeSet(e.target.value); }}
                >

                        {props?.expiryValue?.map((item) => <MenuItem key={item._id} value={item?.minutes}>{item?.label}</MenuItem>)}



                    </SelectValidator>
                </Grid>
                {/* hiding delivery term from chat */}
                {/* <Grid item xs={12}>
                <FormControl component="fieldset" color="primary">
                    <FormLabel component="legend">Delivery Term <sup style={{ color: "red" }}>*</sup></FormLabel>
                    <RadioGroup aria-label="deliveryTermId" value={deliveryTermId} name="deliveryTermId" onChange={e => {
                        deliveryTermIdSet(e.target.value);
                    }}
                        style={{ display: "inline" }}
                        defaultValue={deliveryTermId}
                    >

    {props?.deliverytermsArray?.map((dev) => (
									 <FormControlLabel
                                     value={dev?._id}
                                     control={<Radio size="small" color="primary" />}
                                     label={dev?.name}
                                 />
								))}
                   
                    </RadioGroup>
                </FormControl>
            </Grid> */}
                {/* {!showCommentField && <Button type='button' color="primary" onClick={e => showCommentFieldSet(true)}>+ Add Special Comments</Button>} */}
                {/* {showCommentField && */}
                <Grid item xs={12}>
                    <TextValidator variant="outlined" fullWidth size="small" rows="2" multiline={true}
                        label="Additional Comments:"
                        id="comment"
                        name="comment"
                        value={comment}
                        onChange={e => { commentSet(e.target.value); modificationSet((props.specialRequest == null && e.target.value.length == 0) ? false : (props.specialRequest != null && props.specialRequest == e.target.value) ? false : true); }}
                        inputProps={{
                            maxlength: 500
                            // maxlength: globalSetting?.settings_special_comment_length

                        }}
                        helperText={<Box align="right">Words: {comment.length}<span style={{ color: "#2264e9" }}>/500</span></Box>}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <ImageUploadingInput images={images} onImageChange={(selectedImages) => imagesSet(selectedImages)} />
                    </Box>
                </Grid>

                <Grid item xs={12} >

                    <Button
                        aria-label="Submit"
                        fullWidth
                        variant='contained'
                        type="submit"
                        color="primary"
                        disabled={isValidPrice === false || isValidQuantity === false || props.sendingMessage}
                        //disabled={props.sendingMessage}
                        onClick={e => {
                            //  alert('Please')

                            // fetchUserReferences()
                            e.stopPropagation()
                            e.preventDefault()
                            formEl.current.submit()
                        }}
                    >
                        {props.sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}{(props.reBid || props.reOffer) ? "SUBMIT" : (props.product_type == 1) ? 'SEND YOUR OFFER' : 'SEND YOUR BID'}
                    </Button>
                </Grid>
            </Grid>
    </ValidatorForm>
}
import packages from './config/packages'
import packagesInternational from './config/packagesInternational';
export const config = {
	app_name: process.env.REACT_APP_NAME,
	// api_url: process.env.REACT_APP_API_URL + '/api',
	// admin_api_url: process.env.REACT_APP_API_URL + '/admin-api/v2',
    admin_api_commodity_url: process.env.REACT_APP_API_URL_COMMODITY+'/api/admin',
    common_api_commodity_url: process.env.REACT_APP_API_URL_COMMODITY+'/api',
    admin_api_commodity_login:process.env.REACT_APP_API_URL_COMMODITY+'/api/auth/admin',
    api_commodity_login:process.env.REACT_APP_API_URL_COMMODITY+'/api/auth',
    ai_api_commodity_url: process.env.REACT_APP_API_URL_ASST_COMMODITY,

    // cms_url: process.env.REACT_APP_CMS_URL,
	// cms_api_url: process.env.REACT_APP_CMS_API_URL,

    login_url: process.env.REACT_APP_API_URL_COMMODITY + '/api/login',
	// base_url: process.env.REACT_APP_API_URL,

    console_base_path: '/console',

    user_page_transition_timeout: 1200,
    admin_page_transition_timeout: 1200,
    dealer_page_transition_timeout: 1200,
    marketing_page_transition_timeout: 1200,

    crypto_key: process.env.REACT_APP_CRYPTO_KEY,
    channelize_public_key: process.env.REACT_APP_CHANNELIZE_PUBLIC_KEY,
    channelize_private_key: process.env.REACT_APP_CHANNELIZE_PRIVATE_KEY,
    appyflow_url: process.env.REACT_APP_APPYFLOW_URL,
    appyflow_key_secret: process.env.REACT_APP_APPYFLOW_KEY_SECRET,
    idle_logout_time: 1000 * 60 * process.env.REACT_APP_IDLE_LOGOUT_TIME,
    tcsValue: 0.01,
    cloudconnect_token_id: process.env.REACT_APP_CLOUDCONNECT_TOKEN_ID,
    cloud_connect_c2c_url: process.env.REACT_APP_CLOUDCONNECT_CLICK2CALL_URL,
    packages: packages,
    packagesInternational: packagesInternational,
};

import resource from 'resource-axios';
import axios from 'axios';
import { config } from '../../config';

export const CommodityAssistant = resource(config.ai_api_commodity_url, {

    initializeUser: (params) => {
        return axios.post(`${config.ai_api_commodity_url}/initialize-user`, params)
    },
    chat: (params) => {
        return axios.post(`${config.ai_api_commodity_url}/chat`, params)
    },
}, axios);
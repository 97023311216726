import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({ buttonText, displayText, result, idType, idValue, onClick }) => {
  const handleClick = () => {
    onClick({
      displayText,
      result,
      idType,
      idValue
    });
  };

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      sx={{
        margin: 1,
        minWidth: '120px',
        textTransform: 'none',
        width: '90%',
        justifyContent: 'flex-start',
        textAlign: 'left',
        whiteSpace: 'normal',
        '&:hover': {
          backgroundColor: 'primary.dark',
        }
      }}
    >
      {buttonText}
    </Button>
  );
};

export default CustomButton;

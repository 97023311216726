import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AudioRecorder from './AudioRecorder';

const MessageInput = ({ onSendMessage, disabled }) => {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() && !disabled) {
      onSendMessage(message);
      setMessage('');
    }
  };
  
  // const handleAudioTextSubmit = (text) => {
  //   console.log('message in submit', text)
  //   if(text.trim() && !disabled) {
  //     onSendMessage(text);
  //     setMessage('');
  //   }
  // };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      {/* <AudioRecorder sendAudioText={(e) => {
        console.log('audio text received', e);
        // setMessage(e);
        handleAudioTextSubmit(e);
      }} /> */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyUp={handleKeyPress}
        disabled={disabled}
        sx={{ flexGrow: 1 }}
        aria-label="Message input"
      />
      <IconButton
        color="primary"
        type="submit"
        disabled={!message.trim() || disabled}
        sx={{
          alignSelf: 'center',
        }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default MessageInput;

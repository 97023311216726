import React, { Component, Fragment } from "react";
import { getPartnerListLabel } from "../../helpers";
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Button,
    Box,
    Checkbox,
    Grid,
    TableContainer,
    Paper,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { Product } from "../../api/resources/Product";
import withStyles from '@mui/styles/withStyles';
import LanguageTranslate from '../partials/UnderScoreLanguage'
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import eventsTracking from "../../eventsTracking";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";

const styles = {
    marginBottom: {
        marginBottom: 1,
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'center',
     
        gridGap: '5px',
    },
   
    textMuted: {
        color: '#6A6A6A',
        fontSize: '14px',
    }
};

/**
 *
 * @name ReviewCompanyForm
 * @alias ReviewCompanyForm
 * @component
 * @exports ReviewCompanyForm
 *
 * @description
 * Preview of add company details
 */

export class ReviewCompanyForm extends Component {
   
    constructor(props) {
        super(props);
        console.log('props',props)
        this.state = {
            inputs: props.values[0],
            consentAgreement: 0,
            consentAgreement_error: false,
            cities: [],
            states: []
        };
    }

    componentDidMount() {
        console.log('op1',this.state.inputs)
//         if (this.state.inputs?.company_region
// ) {
//             console.log('op2',this.state.inputs?.company_region
// )
//             this.getState(this.state.inputs?.company_region
// );
//         }
        if (this.state.inputs?.region_state) {
            this.getCity(this.state.inputs?.region_state);
        }
    }

    getCity = state_id => {
        var params = {};
        params.state_id = state_id;
        //console.log(params)
        CommodityCommon.getAllCity(params)
            .then(response => {
                console.log('response',response)
                this.setState({
                    cities: response.data.data
                });
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => { });
    };

    // getState = country_id => {
    //     var params = {};
    //     console.log('country_id', country_id)
    //     params.country_id = country_id;

    //     Product.getStates(params)
    //         .then(response => {
    //             console.log('styate',response)
    //             this.setState({

    //                 states: response.data.data
    //             });
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         })
    //         .finally(() => { });
    // };


    consentAgreementCheckbox = () => {
        // checkbox validation
        this.setState({ consentAgreement_error: false });
        if (this.state.consentAgreement === 0) {
            this.setState({ consentAgreement_error: true });
            return false;
        }

        return true;
    };

    handleInputChange = e => {
        // e.persist();
        var inputs = this.state.inputs;
        console.log('inputs',inputs);
        inputs[e.target.name] = e.target.value;

        if (e.target.name == "consentAgreement") {
            this.setState({
                consentAgreement: this.state.consentAgreement == 0 ? 1 : 0
            });
        }

        this.setState({
            inputs: inputs
        });
        this.props.onValuesChange(inputs)
    };

    handleSubmit = e => {
        e.preventDefault();

        // if (!this.consentAgreementCheckbox()) {
        //     return false;
        // }

        // this.props.onSubmit(this.state.inputs);
        this.props.onSubmit();
        // eventsTracking.ekycSuccess(e);
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const { values } = this.props;
        const preFIllInput = this.props.preFIllInput;
        const cities = this.props.preFIllInput.cities;
        const coaltypes = this.props.coaltypes;
        const countries = this.props.countries;
        const states = this.props.states;
        //console.log(values[0].region_name)
        return (
            <div className="reviewAddCompanyForm">
                {/* <Button onClick={()=>console.log('res',preFIllInput )}>click</Button> */}
                <Box mb={2} className={this.props.classes.gridCol2} >
                    <Box mb={2}>
                        <small className={this.props.classes.textMuted}>Company Name</small> <br />
                        <strong>
                            {values[0]?.company_name}
                        </strong>
                    </Box>
                    {/* <Box mb={2}>
                        <small className={this.props.classes.textMuted}>Company Type</small> <br />
                        <Button onClick={()=>console.log('op3',values[0])}>click</Button>
                        <strong>
                            {values[0]?.company_type == "1" ? 'Private Limited' : values[0]?.company_type == "2" ? 'Limited' : values[0]?.company_type == "3" ? 'Proprietorship' : values[0]?.company_type == "4" ? 'Partnership' : values[0]?.company_type == "5" && 'Limited Liability Partnership'}</strong>
                    </Box> */}

                    <Box mb={2}>
                        <small className={this.props.classes.textMuted}>Company Address</small> <br />
                        {/* <Button onClick={()=>console.log(values)}>click</Button> */}
                        <strong>{values[0].company_address}</strong>
                        {/* <strong> 
                            {values[0]?.company_address}, {values[0]?.region_country &&
                                countries.find(
                                    city =>
                                        city.id ==
                                        values[0].region_country
                                ).name
                            }, {(this.state.states && this.state.states.length > 0) && values[0]?.region_state &&
                                this.state.states.find(
                                    city =>
                                        city.id ==
                                        values[0].region_state
                                ).region_name
                            }, {(this.state.cities && this.state.cities.length > 0) && values[0]?.region_name &&
                                this.state.cities.find(
                                    city =>
                                        city.id ==
                                        values[0].region_name
                                ).region_name
                            }, {values[0].company_pincode}
                        </strong> */}
                    </Box>

                    <Box mb={2}>
                        <small className={this.props.classes.textMuted}>GSTIN / Tax ID</small> <br />
                        <strong>
                            {values[0]?.company_tin}
                        </strong>
                    </Box>
                    {/* <Box mb={2}>
                        <small className={this.props.classes.textMuted}>Industry Type</small> <br />
                        <strong>{values[0]?.industry_id &&
                            preFIllInput.industries.find(
                                industry =>
                                    industry.id ===
                                    values[0].industry_id
                            ).name
                        }</strong>
                    </Box> */}
                    {this.props.phoneCode == "+91" &&
                        <Box mb={2}>
                            <small className={this.props.classes.textMuted}>PAN Number</small> <br />
                            <strong>{values[0]?.company_pan}</strong>
                        </Box>
                    }
                   
                    {this.props.phoneCode == "+91" &&
                        <Box mb={2} >
                           
                            <InputLabel>City<sup style={{ color: "red" }}>*</sup></InputLabel>
                          {console.log(this.state.cities)}
                            <Select
                                variant="outlined"
                                fullWidth
                                size="small"
                                name="region_name"
                                value={values[0].name}
                                onChange={e => { this.handleInputChange(e) }}
                            >
                                {(this.state.cities && this.state.cities.length > 0) && this.state.cities.slice().sort((a, b) => a.name.localeCompare(b.name)).map((city, key) => (
                                    <MenuItem
                                        value={city._id}
                                        key={city._id}
                                    >
                                        {city.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    }
                </Box>
                <Box my={2} textAlign="center" mr={1}>
                    {/* <Button type='button' variant="contained" color="secondary" onClick={e => this.props.onBack()}><LanguageTranslate s='Back' /></Button>{' '} */}
                    <Button
                        type="button"
                        onClick={e =>
                            this.handleSubmit(e)
                        }
                        variant="contained"
                        color="primary"
                        style={{ minWidth: '300px' }}
                        size="large"
                        disabled={
                            this.props
                                .submittingForm
                        }
                    >
                        <LanguageTranslate s='Submit' />
                    </Button>
                </Box>
            </div>
        );
    }
}

export default (withStyles(styles)(ReviewCompanyForm));
